// localStorage key name for storing our redux store
import { setUrlParams } from '@lux/helpers';

export const lsKeyName = 'detox-state';

export const sdAccountSummary = `personal/my-account/manage-accounts/account-summary`;
export const getUIAMLoginUrl = (targetURL, isSpringD = false) => {
  const baseUrl = isSpringD
    ? process.env.GATSBY_AEM_URL
    : process.env.GATSBY_HOST;
  const onePassUrl = setUrlParams(process.env.GATSBY_ONEPASS_URL, {
    flowId: 'f0e01c37-233f-4457-90d9-f94daeadd517',
    targetUrl: `${baseUrl}/${targetURL}`
  });
  return onePassUrl;
};

/**
 * number of months for service to become CI eligible.
 * defaults to 12 months
 * @type {number}
 */
export const CI_ELIGIBLE_MONTHS = process.env.GATSBY_CI_ELIGIBLE_MONTHS
  ? parseInt(process.env.GATSBY_CI_ELIGIBLE_MONTHS)
  : 12;
