import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { Spacing, Text, TextLink } from '@dls/web';
import {
  addonsActions,
  api,
  authActions,
  cartActions,
  checkoutActions,
  fulFillmentActions,
  orderActions,
  userActions,
  voucherActions
} from '@detox/actions';
import { trans as t } from '../../helpers/localisation';
import { ShoppingCart as ShoppingCartModule } from '@common-modules/shopping-cart';
import { getUrlParams, noop } from '@lux/helpers';
import GenericError from './GenericError';
import CPModal from '../Checkout/CPModals';
import {
  createShoppingCartDataModelVoucher,
  getUpfront,
  isBibAvailable,
  isMobileShareOrSimOnlyPlan,
  validateTradeIn
} from '../../helpers/shoppingCartHelper';
import {
  checkShoppingCartEnabled,
  defaultGenericErrorModal,
  getCartError,
  getCartErrorDataModel,
  getShoppingCartDataModel,
  overrideRemovableAddons
} from '../../helpers/cart';
import {
  getAddonIdsByProductSpecContainmentID,
  getTradeInSelection
} from '../../helpers/addon';
import {
  dataLayerPush,
  flattenNodes,
  getAemConfigs
} from '../../helpers/common';
import createDescription from '../../helpers/create-description';
import useResetVoucher from '../../hooks/useResetVoucher';
import { CART, REQUEST_STATUS, VOUCHER_ACTION } from '../../constants';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import {
  CP9_FLOW,
  RED_PHASE,
  TRADEIN,
  VOUCHER
} from '../../types/featureFlag.types';
import isSimOnlyPlan from '../../helpers/is-sim-only-plan';
import { isRedMember } from '../../reducers/user';
import { isSimOnlyIPP } from '../../reducers/plan';
import { isNewSignUp } from '../../reducers/order';
import {
  navigateToPlansPage,
  navigateToProductDetailsPage
} from '../../helpers/navigation';
import useStockAvailability from '../../hooks/useStockAvailability';
import useStockCheck, { STOCK_CHECK_TYPE } from '../../hooks/useStockCheck';
import OutOfStock from './OutOfStock';
import { CollectAtStoreModal } from './CollectAtStoreModal';
import { PRODUCT } from '../../constants/product';
import CART_CONSTANTS from '../../constants/cart';
import { getRedemptionType } from '../../helpers/voucher';
import { CREDIT_LIMIT_INDICATOR } from '../CreditLimitRetrieved/constants';
import { AEM_CONFIG_KEYS, CP_ERROR_CODES } from '../../config/common';
import { getCreditLimitErrorProps } from '../../helpers/credit-limit';
import CCEMisMatchError from '../CCEPrompt/CCEMisMatchErrorModal';
import getIsRoiMismatch from '../../helpers/is-roi-mismatch';
import { useAmendedTab } from '../../hooks/useAmendedTab/useAmendedTab';
import { ucfirst } from '../../helpers/stringHelper';
import { getShoppingCartDataLayer } from '../../helpers/dataLayerHelpers';
import { useBrowseBackPopup } from '../../hooks/useBrowseBackPopup';
import { getUIAMLoginUrl } from '../../config';
import { navigation } from '../../middlewares/navigation-constants';
import { addonMcAfeeTripleCheck } from '../AddonCatalog/addon-helper';
import { getTradeInDetails } from '../../selectors';
import { formatDate } from '../Fulfilment/helpers';
import { setBibDisplayData } from '../../reducers/cart';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { CONTAINMENT_IDS } from '../../constants/addon';
import { useModal } from '../../hooks/useModal';
import { SkeletonType } from '../SkeletonLoader';
import { useLoader } from '../../hooks/useLoader';
import { getItemSkuToOrderIdMapping } from '../Checkout/helper';
const fetchOfflineStockApi = api.aem.offlineStockAvailability;
const { VOUCHER_REDEMPTION_TYPE } = CART_CONSTANTS;
export const ShoppingCart = ({
  voucher,
  accessory,
  user,
  contactId,
  customerId,
  contact,
  plan,
  addons,
  checkout,
  order,
  cart,
  bridge,
  data,
  product,
  promoOrderDetails,
  availableAddons,
  selectedAddons,
  updateAddons = noop,
  getShoppingCartData = noop,
  emptyCart = noop,
  addTradeInToOrder,
  removeAddonFromOrder,
  getAllVouchers,
  addVouchers,
  removeVouchers,
  getBillingPreference,
  clearCheckoutError,
  getUserInformation,
  removeAccessoriesFromOrder,
  resetModifyAccessories,
  resetModifyAddons,
  updateMainLineNumber,
  setSelectedService,
  resetVouchers,
  addCustomerContact,
  cisUserInfo,
  isMtposStockAvailable,
  setAcknowledgeNotification,
  clearDeliveryData,
  subscriberData,
  simOnlyIPP,
  isRedVoucherEligible,
  roiValidatedData,
  userInformation,
  tradeInCIDValue,
  setBibDisplayData,
  bib,
  updateOrderToRunRules,
  specialPromo
}) => {
  const {
    allAddonMobileLob,
    allAspireBillingOffer,
    allTradeIn,
    allErrorMessage,
    allPlan,
    allVoucherRedemption,
    allVariableConfig,
    fulfilment
  } = data;

  const allVoucherRedemptionFlattened = flattenNodes(allVoucherRedemption);
  const {
    siteMetadata: { siteUrl }
  } = data.site;
  const skipEmptyCartPage = useRef(false);
  const { productOrder, addedToContact } = order;
  const { cartOrder, acknowledgeNotification } = cart;
  const { selectedProduct } = product;
  const { selectedPlan } = plan;
  const [cceMismatch, setCceMismatch] = useState(false);
  const [offlineStockLoading, setOfflineStockLoading] = useState(false);
  const isSop = isSimOnlyPlan(selectedPlan);
  const variableConfigs = flattenNodes(allVariableConfig) || [];
  const { setModalData, renderedModal } = useModal();

  const [
    eligibleBibGroupIds = '',
    bibTnc = '',
    removeAddonIDs = ''
  ] = getAemConfigs(variableConfigs, [
    AEM_CONFIG_KEYS.DETOX_BIB_DEVICE_GROUP_ID,
    AEM_CONFIG_KEYS.DETOX_BIB_TNC,
    AEM_CONFIG_KEYS.DETOX_SHOPPING_CART_REMOVE_ADDON_IDS
  ]);

  const addOnStateError = useMemo(
    () => ({
      error: addons.error && addons.isBib,
      redirectNeeded: false
    }),
    [addons]
  );
  const { renderError } = useErrorHandler({
    states: [addOnStateError]
  });
  let applicableVouchers =
    voucher?.voucherByServiceId?.[cartOrder?.serviceId] ||
    voucher?.voucherByServiceId?.['other'] ||
    [];

  // Covert from array to object and upppercase.
  const redemptionTypeByVoucher = allVoucherRedemptionFlattened?.reduce(
    (acc, cur) => {
      const { voucherType } = cur;

      const data = {
        ...cur,
        rules: cur.rules.map(rule => ({
          ...rule,
          redemptionType: ucfirst(rule.redemptionType)
        })),
        defaultRedemptionType: ucfirst(cur.defaultRedemptionType)
      };

      return { ...acc, [voucherType]: data };
    },
    {}
  );

  const voucherRedemptionType = getRedemptionType(redemptionTypeByVoucher, {
    productOrderType: productOrder?.type,
    hasDevice: Boolean(selectedProduct),
    planId: selectedPlan?.mecId
  });

  applicableVouchers = applicableVouchers.map(voucher => {
    return {
      ...voucher,
      redemptionType:
        voucherRedemptionType[voucher.voucherTypeX8] ||
        VOUCHER_REDEMPTION_TYPE.IMMEDIATE
    };
  });

  const {
    loading: onlineStockLoading,
    stockAvailable: { status: onlineStockAvailable, notAvailableSkus }
  } = useStockCheck(STOCK_CHECK_TYPE.MTPOS);

  const [localStock, localStockLoading] = useStockAvailability(
    selectedProduct,
    'sessionToken',
    selectedProduct?.groupId,
    true
  );

  const cceProceedClicked = async () => {
    setCceMismatch(false);
    await handleEmptyCart();
    navigate('/', { replace: true });
  };

  const isForwardFromAddonPage = previousPage => {
    return previousPage === `/${navigation.ADDONS}`;
  };

  const { resetLoginAndCheckoutData, backForwardData } = useBrowseBackPopup({
    callbackOnBackForwardPressed: async options => {
      if (isForwardFromAddonPage(options?.previousPage)) {
        await updateOrderToRunRules({
          productId: productOrder?.productId,
          productOrderItemId: productOrder?.productOrderItemId,
          selectedAddonIds: addons.selectedAddons,
          promoOrderDetails: {
            ...promoOrderDetails,
            sourcePlan: user?.selectedService?.offeringId
          }
        });
        fetchShoppingCart();
      }
      resetLoginAndCheckoutData();
    }
  });

  const tradeInProductSpecContainmentId =
    addons.containmentIdData?.[CONTAINMENT_IDS.TRADE_IN]
      ?.productSpecContainmentID;

  const bibCustomLiner = (
    <Text type="smallBody">
      {t('BIB_NEEDS_HELP_TEXT', {
        tnc: (
          <TextLink
            inline
            onClick={() => {
              window.open(bibTnc, '_blank');
            }}
          >
            {t('VIEW_TNC')}
          </TextLink>
        )
      })}
    </Text>
  );

  const setBibData = () => {
    if (bib) {
      const hasBibBo = !!bib.tradeInBo?.productID;
      setBibDisplayData({
        title: t('BIB_TITLE'),
        subTittle: t('BIB_VALID_TILL', {
          date: formatDate(new Date(Number(bib.endDate)), 'dd MMM yyyy')
        }),
        status: '',
        imei: bib.imei,
        isApplied: hasBibBo,
        customLiner: bibCustomLiner
      });
    }
  };

  useEffect(() => {
    setBibData();
  }, [bib, bib?.tradeInBo?.productID]);

  useEffect(() => {
    const updateOrder = async () => {
      await addCustomerContact({
        contact,
        customerId,
        productOrder,
        isAnonymous: true
      });
    };

    if (!addedToContact && contact && customerId && productOrder) {
      updateOrder();
    }
  }, [addedToContact, contact, customerId, productOrder]);

  const [toastMessage, setToastMessage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const isUserLoggedin = Boolean(user?.information?.clientContext);
  const [isMounted, setIsMounted] = useState(false);
  const plans = useMemo(() => flattenNodes(allPlan), [allPlan]);
  let dataModel = {};

  const urlParams = getUrlParams();

  useEffect(() => {
    if (
      !acknowledgeNotification &&
      voucher?.vouchers?.length > applicableVouchers.length
    ) {
      setToastMessage(t('NOTIFICATION_CANT_SEE_VOUCHER'));
    } else if (
      urlParams.creditLimitIndicator === CREDIT_LIMIT_INDICATOR.INCREASED
    ) {
      setToastMessage(t('CREDIT_LIMIT_SUCCESSFULL'));
    }
  }, [voucher?.vouchers, applicableVouchers, urlParams.creditLimitIndicator]);

  useEffect(() => {
    const getUser = async () => {
      if (!isUserLoggedin) {
        await getUserInformation();
      }
    };

    getUser();
    dataLayerPush(
      getShoppingCartDataLayer({
        selectedPlan,
        selectedVariant: selectedProduct,
        accessories: accessory?.selectedAccessories,
        location: window.location,
        order: order.productOrder
      }),
      true
    );
  }, []);

  const { renderAmendedErrorPopup } = useAmendedTab();

  const { brands, modelByBrand } = getTradeInSelection(
    flattenNodes(allTradeIn)[0]?.brands
  );

  const fetchVouchers = () => {
    getAllVouchers({ customerId, contactId });
  };

  const fetchShoppingCart = useCallback(() => {
    const { productOrderId, productOrderReferenceNumber, type } = productOrder;

    let subscriberItem = '';
    if (
      user?.selectedService?.serviceId &&
      subscriberData &&
      subscriberData.length > 0
    ) {
      subscriberItem = subscriberData.find(
        item => item.primaryResource === user?.selectedService?.serviceId
      );
    }
    const productImage = selectedProduct?.imageGallery?.find(
      img => img.name === selectedProduct?.colour
    );

    const imageUrl =
      siteUrl + productImage?.images[0]?.image?.childImageSharp?.fixed?.src;

    getShoppingCartData({
      productOrderId: productOrderId,
      productOrderReferenceNumber: productOrderReferenceNumber,
      technicalVasIds: allAddonMobileLob.edges.map(edge => edge.node.addonId),
      aspireBillingOffers: allAspireBillingOffer.edges.map(
        edge => edge.node.boId
      ),
      contactId: contactId,
      subscriberItem: subscriberItem,
      custType: cisUserInfo ? 'CIS' : 'RES',
      txn: type,
      deviceDisplayName: selectedProduct?.title,
      deviceCapacity: selectedProduct?.size,
      deviceColor: selectedProduct?.colour,
      deviceImagePath: imageUrl,
      tradeInProductSpecContainmentId
    });
  }, [
    allAddonMobileLob.edges,
    allAspireBillingOffer.edges,
    getShoppingCartData,
    productOrder,
    subscriberData
  ]);

  useEffect(() => {
    if (contact) {
      getBillingPreference({
        contactId: contact.contactId,
        nric: contact.indentValue,
        indentType: contact.indentType
      });
    }
  }, [contact]);

  useEffect(() => {
    if (
      productOrder &&
      !isForwardFromAddonPage(backForwardData?.previousPage)
    ) {
      fetchShoppingCart();
    }
    setIsMounted(true);
  }, [fetchShoppingCart, productOrder]);

  useEffect(() => {
    if (isFeatureFlagEnabled(VOUCHER) && contactId && customerId) {
      fetchVouchers();
    }

    return () => {
      resetVouchers();
    };
  }, [contactId, customerId]);
  useResetVoucher();

  useEffect(() => {
    if (addons.isRemoveBibSuccess) {
      setBibDisplayData({
        status: '',
        hintMessage: '',
        isApplied: false
      });
    }
  }, [addons.isRemoveBibSuccess]);

  /* refresh quote data if anything is changed */
  useEffect(() => {
    if (voucher.modified || accessory.modified || addons.modified) {
      fetchShoppingCart();

      if (voucher.modified) {
        fetchVouchers();
      } else if (accessory.modified) {
        resetModifyAccessories();
      } else if (addons.modified) {
        resetModifyAddons();
      }
      if (addons.isAddBibSuccess) {
        setBibDisplayData({
          status: 'success',
          hintMessage: t('BIB_APPLIED'),
          isApplied: true
        });
      }
    }
  }, [voucher.modified, accessory.modified, addons.modified]);

  useEffect(() => {
    if (cart.checkoutError) {
      clearCheckoutError();
    }
  }, []);

  const localStockAvailability = () => {
    let deviceData = {};
    if (localStock.length > 0) {
      deviceData = localStock.find(
        item => item.itemCode === cart?.order?.mobile?.device?.sku
      );
    }
    return deviceData?.availableQty > 0;
  };

  const getOrderUpdateRulesData = selectedAddonIds => {
    const { productOrderItemId, productId } = productOrder;

    return {
      productId,
      productOrderItemId,
      selectedAddonIds: selectedAddonIds || addons.selectedAddons,
      promoOrderDetails: {
        ...promoOrderDetails,
        sourcePlan: user?.selectedService?.offeringId
      }
    };
  };

  const showSopTradeInNoSpecIdPopup = () => {
    setModalData({
      visible: true,
      title: t('GENERIC_ERROR_TITLE'),
      message: t('GENERIC_ERROR_MESSAGE'),
      onClose: () => {
        window.history.back();
      }
    });
  };

  const handleAddTradeIn = async item => {
    if (!tradeInProductSpecContainmentId) {
      return showSopTradeInNoSpecIdPopup();
    }
    const { productOrderItemId } = productOrder;
    const tradeInRulesData = getOrderUpdateRulesData();
    await addTradeInToOrder({
      productOrderItemId,
      ...item,
      tradeInRulesData,
      addDefaultChildItemsImplicitly: isSop,
      tradeInProductSpecContainmentId
    });
  };

  const handleRemoveTradeIn = async () => {
    await handleRemoveAddon(cartOrder?.tradeInDetails?.tradeInBo, true);
  };

  const handleRemoveAddon = async (
    item,
    removeTradeIn = false,
    isRemoveBib = false
  ) => {
    const { productOrderItemId } = productOrder;

    let removedAddonIds = getAddonIdsByProductSpecContainmentID(
      item.productSpecContainmentID,
      availableAddons
    );
    const tradeInCID = item.productSpecPricing?.childPricingSchema?.id;
    removedAddonIds =
      removeTradeIn && tradeInCID
        ? [...removedAddonIds, tradeInCID]
        : removedAddonIds;

    const selectedAddonIds = selectedAddons.filter(
      addonId => !removedAddonIds.includes(addonId)
    );

    const includeTradeInCID =
      tradeInCIDValue &&
      !removeTradeIn &&
      !(selectedAddonIds || []).includes(tradeInCIDValue);

    // triple protect handling
    const mcafeeTriple = addonMcAfeeTripleCheck(item.cID);
    const runUpdateOrderRulesData = mcafeeTriple
      ? getOrderUpdateRulesData(selectedAddonIds)
      : null;

    await removeAddonFromOrder({
      addon: item,
      productOrderItemId,
      promoOrderDetails,
      selectedAddonIds: includeTradeInCID
        ? [...selectedAddonIds, tradeInCIDValue]
        : selectedAddonIds,
      withPromoString: true,
      runUpdateOrderRulesData,
      removeWithBillingProduct:
        Boolean(mcafeeTriple) || (removeTradeIn && isSop),
      isRemoveBib
    });

    updateAddons({
      selectedAddons: includeTradeInCID
        ? [...selectedAddonIds, tradeInCIDValue]
        : selectedAddonIds,
      availableAddons,
      atomicAddons: addons.atomicAddons,
      selectedAddonsBo: addons.selectedAddonsBo,
      addons5G: addons.addons5G,
      bundleAddonsData: addons.bundleAddonsData,
      containmentIdData: addons.containmentIdData
    });
  };

  const handleUpdateVoucher = (id, actionType) => {
    const payload = {
      productOrderReferenceNumber: productOrder.productOrderReferenceNumber,
      customerId,
      contactId,
      productOrderItemId: productOrder.productOrderItemId,
      voucherIds: [id],
      ...(isFeatureFlagEnabled(RED_PHASE) && {
        redemptionType: applicableVouchers.find(voucher => voucher.idX8 === id)
          .redemptionType
      })
    };
    if (actionType === VOUCHER_ACTION.ADD) {
      addVouchers(payload);
    } else if (actionType === VOUCHER_ACTION.REMOVE) {
      removeVouchers(payload);
    }
  };

  const handleRemoveAccessories = async item => {
    await removeAccessoriesFromOrder({
      customerId,
      productOrderItemId: productOrder.productOrderItemId,
      productId: item.productID
    });
  };

  const fetchOfflineStock = async groupId => {
    try {
      setOfflineStockLoading(true);
      const response = await fetchOfflineStockApi(groupId);
      return response[0].items;
    } catch (e) {
      return [];
    } finally {
      setOfflineStockLoading(false);
    }
  };

  const getAccessoriesItemsList = (propKey, propValue) => {
    if (cartOrder?.accessories && cartOrder?.accessories.length > 0) {
      const accessoriesItemList = cartOrder.accessories.map(accessory => ({
        [propKey]: accessory[propValue ? propValue : propKey]
      }));
      return accessoriesItemList;
    }

    return [];
  };

  const handleCheckout = async () => {
    const hasRoiDataMismatch = getIsRoiMismatch(
      userInformation?.userDetails?.custId,
      roiValidatedData
    );

    if (hasRoiDataMismatch) {
      setCceMismatch(true);
    } else {
      if (!isMtposStockAvailable) {
        const deviceSku = cart?.order?.mobile?.device?.sku;
        const offlineStockAvail = await fetchOfflineStock(
          selectedProduct?.groupId
        );
        const availableItems = offlineStockAvail.map(sku => {
          const offLineStock =
            sku?.skuId === deviceSku &&
            sku.data.find(
              item =>
                PRODUCT.STOCK_AVAILABLE_STATUS.includes(item?.stock_status) &&
                Number(item?.available_quantity) > 0 &&
                item?.store_id !== PRODUCT.STORE_ID.WARE_HOUSE
            );
          return offLineStock;
        });

        const itemCollectAtStore = availableItems.find(
          item => item?.store_id !== undefined
        );

        cart.itemCollectionAtStore = itemCollectAtStore;

        const removedItemDetails =
          (cartOrder?.freebies && cartOrder?.freebies.length > 0) ||
          (cartOrder?.accessories && cartOrder?.accessories.length > 0) ||
          cartOrder?.tradeInDetails;
        if (itemCollectAtStore && removedItemDetails) {
          setOpenModal(true);
        } else {
          await handleCheckoutContinue();
        }
      } else {
        await handleCheckoutContinue();
      }
    }
  };

  const handleCheckoutContinue = async (clearAccessories = false) => {
    let updatedNotAvailableSkus = notAvailableSkus;
    if (clearAccessories) {
      updatedNotAvailableSkus = [
        ...notAvailableSkus,
        ...getAccessoriesItemsList('sku', 'skuId')
      ];
      const accessoryIDsToRemove = getAccessoriesItemsList('productID');
      setOpenModal(false);
      // api call to remove all accessories
      handleRemoveAccessories({ productID: accessoryIDsToRemove });
    }

    const itemSkuToOrderIdMapping = {
      [selectedProduct?.deviceCode || '']:
        productOrder?.productOrderItemId || ''
    };

    const {
      sim,
      productId,
      productOrderId,
      productOrderItemId,
      productOrderReferenceNumber
    } = productOrder;
    const removedContainedProducts = dataModel?.outOfStockItem?.dataSource?.map(
      item => ({ productID: item.productID })
    );
    const order = {
      type: productOrder?.type,
      simOptions: sim,
      productId: productId,
      productOrderId: productOrderId,
      productOrderItemId: productOrderItemId,
      productOrderReferenceNumber: productOrderReferenceNumber,
      cart: cart,
      skipFulfillmentCheck:
        urlParams.creditLimitIndicator === 'INCREASED' ? true : false,
      removedContainedProducts,
      notAvailableSkus: updatedNotAvailableSkus,
      userInfo: {
        customerId,
        ...contact
      },
      isSimOnlyIPP: simOnlyIPP,
      itemSkuToOrderIdMapping: getItemSkuToOrderIdMapping(
        selectedProduct?.deviceCode,
        productOrder?.productOrderItemId
      )
    };
    await checkout(order);
  };

  /**
   * Fires the action to empty the cart
   *
   * @returns {Promise<void>}
   */
  const handleEmptyCart = async (showEmptyCart = false) => {
    if (productOrder) {
      const { productOrderId, productOrderItemId } = productOrder;
      skipEmptyCartPage.current = !showEmptyCart;
      await emptyCart({
        customerId,
        productOrderId,
        productOrderItemId
      });
      clearDeliveryData();
    }
  };

  const handleRetrieveVoucher = () => {
    if (!isUserLoggedin) {
      return () =>
        window.open(getUIAMLoginUrl(navigation.SHOPPING_CART_PAGE), '_self');
    } else if (
      voucher.status === REQUEST_STATUS.FAILED &&
      !voucher?.vouchers?.length
    ) {
      return () => fetchVouchers();
    } else {
      return null;
    }
  };

  const handleOnChangePlan = async () => {
    await handleEmptyCart();
    skipEmptyCartPage.current = true;
    if (Boolean(selectedProduct)) {
      navigateToProductDetailsPage(selectedProduct, selectedPlan);
    } else {
      navigateToPlansPage(selectedPlan, cisUserInfo);
    }
  };

  const handleOnChangeNumber = async () => {
    return navigate('/number-selection');
  };

  const onTradeInTncClicked = async () => {
    window.open(CART.TNC_LINK, '_blank');
  };

  const handleChangePhone = async () => {
    await handleEmptyCart();
    return navigate('/');
  };

  const handleChangeMainLine = async serviceId => {
    const selectedServiceId = Object.keys(user.mainLines).find(
      key => key === serviceId
    );
    const selectedMainLine = user.mainLines[selectedServiceId];

    const mainLineOptions = {
      customerId,
      mainLineGroupId: selectedMainLine.groupId,
      productOrderItemId: productOrder.productOrderItemId,
      productId: cart?.order?.mobile?.plan?.productId
    };

    try {
      await updateMainLineNumber(mainLineOptions);

      const selectedService = user.products.find(
        product => product.serviceId === selectedMainLine.mainLineMSISDN
      );
      setSelectedService(selectedService);
    } catch (e) {
      console.log('Exception occurred when updating main line number', e);
    }
  };

  const renderCartErrors = ignoreEmptyCart => {
    const allErrorMessagesFlattened = flattenNodes(allErrorMessage);
    const cartError = getCartError(
      cart.checkoutErrorData,
      allErrorMessagesFlattened
    );

    const placeholderValues = {
      plan: plan?.selectedPlan?.planName
    };
    const cartErrorDataModel =
      getCartErrorDataModel(cartError, placeholderValues) ||
      defaultGenericErrorModal;
    if (!ignoreEmptyCart) {
      handleEmptyCart();
    }

    if (
      isFeatureFlagEnabled(CP9_FLOW) &&
      Object.values(CP_ERROR_CODES).includes(cartErrorDataModel?.errorCode)
    ) {
      const { navigateTo } = getCreditLimitErrorProps(cartErrorDataModel);
      return <CPModal errorData={cartErrorDataModel} navigateTo={navigateTo} />;
    }
    return (
      <GenericError
        title={cartErrorDataModel.title}
        errorText={cartErrorDataModel.errorText}
        primaryButtonText={cartErrorDataModel.primaryButtonText}
        secondaryButtonText={cartErrorDataModel.secondaryButtonText}
        onPrimaryClick={() => {
          window.location.href = cartErrorDataModel.primaryButtonLink;
        }}
        onSecondaryClick={() => {
          window.location.href = cartErrorDataModel.secondaryButtonLink;
        }}
        footerList={cartErrorDataModel.footerList}
      />
    );
  };

  const isSkeletonLoading =
    cart.skeletonLoading ||
    onlineStockLoading ||
    offlineStockLoading ||
    localStockLoading;

  const isLoading =
    addons.loading ||
    cart.loading ||
    cart.checkoutLoading ||
    accessory.status === REQUEST_STATUS.PENDING ||
    voucher.status === REQUEST_STATUS.PENDING ||
    order.loading ||
    bridge?.isLoading ||
    !isMounted;
  const { Loader } = useLoader({
    loadings: [isLoading],
    skeletonLoadings: [isSkeletonLoading],
    options: {
      type: SkeletonType.sideBar
    }
  });
  if (
    !isLoading &&
    !isSkeletonLoading &&
    (!productOrder ||
      (!selectedProduct &&
        !isMobileShareOrSimOnlyPlan(selectedPlan) &&
        !specialPromo) ||
      !cart.order) &&
    !skipEmptyCartPage.current
  ) {
    navigate('/empty-shopping-cart');
    return null;
  } else if (cart.error || cart.checkoutError || cart.checkoutOrderError) {
    return renderCartErrors(cart.error || cart.checkoutError);
  } else {
    const mobileSubscription = cart?.order?.mobile;
    const isPreviousIpp =
      cart?.order?.mobile?.existingDevice &&
      cart?.order?.mobile?.existingDevice?.ippDetails;

    const existingOrder = {
      device: isPreviousIpp && cart?.order?.mobile?.existingDevice?.model
    };
    const { plan: cartOrderPlan } = mobileSubscription || {};

    const planDescription = createDescription({
      data: selectedPlan.data,
      sms: selectedPlan.sms,
      talktime: selectedPlan.talktime
    });

    let newCartOrder = {
      ...cartOrder,
      selectedService: productOrder?.service,
      monthlyBills: overrideRemovableAddons(
        cartOrder?.monthlyBills,
        removeAddonIDs
      )
    };

    if (cartOrderPlan) {
      newCartOrder = {
        ...newCartOrder,
        selectedPlan: {
          name: cartOrderPlan.name,
          description: planDescription,
          price: cartOrderPlan.price,
          groupName: selectedPlan?.groupName
        }
      };
    }

    const callbacks = {
      handleChangeMainLine
    };

    const onBibTradeInClicked = imei => {
      if (bib.tradeInBo?.productID) {
        return removeBibTradeIn();
      }

      addBibToOrder(imei);
    };

    const removeBibTradeIn = async () => {
      {
        setBibDisplayData({
          status: 'loading',
          hintMessage: ''
        });
        await handleRemoveAddon(bib?.tradeInBo, true, true);
      }
    };

    const addBibToOrder = async imei => {
      const { productOrderItemId } = productOrder;
      if (!bib) return;
      const { brand, model } = bib;
      if (cartOrder?.tradeInDetails?.tradeInBo) {
        return setBibDisplayData({
          status: 'error',
          hintMessage: t('BIB_REMOVE_TRADE_IN_MESSAGE')
        });
      }

      if (!tradeInProductSpecContainmentId) {
        return showSopTradeInNoSpecIdPopup();
      }

      setBibDisplayData({
        status: 'loading',
        hintMessage: ''
      });

      await addTradeInToOrder({
        productOrderItemId,
        brand,
        model,
        imei,
        isAddBib: true,
        addDefaultChildItemsImplicitly: isSop,
        tradeInProductSpecContainmentId
      });
    };

    const shoppingCartObject = {
      cartOrder: newCartOrder,
      selectedProduct: selectedProduct,
      orderType: productOrder?.type,
      mainLines: user?.mainLines,
      userSelectedServiceId: user?.selectedService?.serviceId,
      plans: plans,
      userProducts: user?.products,
      callbacks: callbacks,
      existingOrder: existingOrder,
      cisUser: cisUserInfo
    };

    dataModel = getShoppingCartDataModel(shoppingCartObject);

    const isDeviceOutOfStock =
      selectedProduct &&
      !isSimOnlyPlan(selectedPlan) &&
      !onlineStockAvailable &&
      !localStockAvailability() &&
      !localStockLoading &&
      !isSkeletonLoading;

    const handleNotify =
      !acknowledgeNotification &&
      voucher?.vouchers?.length > applicableVouchers.length
        ? () => setAcknowledgeNotification()
        : urlParams.creditLimitIndicator === CREDIT_LIMIT_INDICATOR.INCREASED
        ? () => {}
        : null;

    const scmVoucher = createShoppingCartDataModelVoucher({
      vouchers: applicableVouchers,
      isCheckoutPriceOffset: cartOrder?.totalCheckOutPrice === 0,
      cisUser: cisUserInfo
    });
    const upfront = getUpfront({
      upfrontPriceCanBeOffset: cartOrder?.totalOriginUpfrontPriceCanBeOffset,
      tradeIn: cartOrder?.tradeInDetails,
      discount: dataModel?.discount,
      vouchers: scmVoucher?.dataSource,
      upfrontPriceCanBeOffsetForTradeIn:
        cartOrder?.totalOriginUpfrontPriceCanBeOffsetForTradeIn
    });
    const tradeInStatus = validateTradeIn({
      tradeIn: cartOrder?.tradeInDetails,
      isHomeDeliveryAvailable: isMtposStockAvailable,
      upfront,
      plan: selectedPlan,
      hasBibTradeIn: !!bib?.tradeInBo?.productID,
      hasDevice: Boolean(selectedProduct),
      fulfilment
    });

    const getNotifyIconType = () =>
      urlParams.creditLimitIndicator === CREDIT_LIMIT_INDICATOR.INCREASED
        ? 'Completed'
        : null;

    return (
      <>
        {isDeviceOutOfStock && (
          <OutOfStock
            selectedProduct={selectedProduct}
            onViewAvailableProduct={handleChangePhone}
          />
        )}

        {!isDeviceOutOfStock && (
          <>
            <Spacing data-testid="shopping-cart-module">
              <Loader>
                <ShoppingCartModule
                  data={{
                    ...dataModel,
                    bibTradeIn: cart.bibDisplayData,
                    tradeIn: {
                      brands,
                      modelByBrand,
                      device: cartOrder?.tradeInDetails,
                      onAddTradeIn: handleAddTradeIn,
                      onRemoveTradeIn: handleRemoveTradeIn,
                      tradeInNotAvailable: !tradeInStatus.available
                    },
                    voucher: {
                      ...scmVoucher,
                      addVoucher: id =>
                        handleUpdateVoucher(id, VOUCHER_ACTION.ADD),
                      removeVoucher: id =>
                        handleUpdateVoucher(id, VOUCHER_ACTION.REMOVE),
                      retrieveVoucher: handleRetrieveVoucher(),
                      error: voucher.status === REQUEST_STATUS.FAILED,
                      link: {
                        url: applicableVouchers.length
                          ? process.env.GATSBY_VOUCHER_TERMS_AND_CONDITIONS_URL
                          : null
                      }
                    }
                  }}
                  callbacks={{
                    onChangePhone: handleChangePhone,
                    onChangePlan: handleOnChangePlan,
                    onChangeNumber: handleOnChangeNumber,
                    onRemoveItem: handleRemoveAddon,
                    onEmptyCart: () => handleEmptyCart(true),
                    onCheckout: handleCheckout,
                    onTradeInTncClicked,
                    onNotify: handleNotify,
                    onBibTradeInClicked
                  }}
                  configs={{
                    loading: isLoading,
                    showBibTradeIn: isBibAvailable({
                      plan: selectedPlan,
                      selectedProduct,
                      flowType: productOrder?.type,
                      eligibleBibGroupIds
                    }),
                    showTradeIn: isFeatureFlagEnabled(TRADEIN),
                    showVoucher: isFeatureFlagEnabled(VOUCHER),
                    showOutOfStock: true,
                    toastIcon: getNotifyIconType(),
                    stickyBoundaryElement: '.stickyBoundary'
                  }}
                  localisation={{
                    TRADE_IN_PHRASE: t('TRADE_IN_PHRASE'),
                    TRADE_IN_MESSAGE: tradeInStatus.message,
                    VOUCHER_MESSAGE: null,
                    NOTIFY_MESSAGE: toastMessage,
                    ACTION_BUTTON_DISMISS: null
                  }}
                />
              </Loader>
            </Spacing>
          </>
        )}

        {cceMismatch && (
          <CCEMisMatchError
            proceedClicked={cceProceedClicked}
            isLoggedInUser={true}
          />
        )}
        {renderAmendedErrorPopup()}
        {renderError()}
        <CollectAtStoreModal
          data-testid="collect-store-modal"
          fulfillmentConfig={fulfilment}
          visible={openModal}
          ctaText={t('PROCEED')}
          onClose={() => setOpenModal(false)}
          onButtonClick={() => handleCheckoutContinue(true)}
        />
        {renderedModal}
      </>
    );
  }
};

ShoppingCart.propTypes = {
  voucher: PropTypes.any,
  accessory: PropTypes.any,
  user: PropTypes.any,
  contactId: PropTypes.any,
  customerId: PropTypes.any,
  contact: PropTypes.any,
  plan: PropTypes.any,
  addons: PropTypes.any,
  checkout: PropTypes.any,
  order: PropTypes.any,
  cart: PropTypes.any,
  bridge: PropTypes.any,
  data: PropTypes.any,
  product: PropTypes.any,
  promoOrderDetails: PropTypes.any,
  availableAddons: PropTypes.any,
  selectedAddons: PropTypes.any,
  updateAddons: PropTypes.any,
  getShoppingCartData: PropTypes.any,
  emptyCart: PropTypes.any,
  addTradeInToOrder: PropTypes.any,
  removeAddonFromOrder: PropTypes.any,
  getAllVouchers: PropTypes.any,
  addVouchers: PropTypes.any,
  removeVouchers: PropTypes.any,
  getBillingPreference: PropTypes.any,
  clearCheckoutError: PropTypes.any,
  getUserInformation: PropTypes.any,
  removeAccessoriesFromOrder: PropTypes.any,
  resetModifyAccessories: PropTypes.any,
  resetModifyAddons: PropTypes.any,
  updateMainLineNumber: PropTypes.any,
  setSelectedService: PropTypes.any,
  resetVouchers: PropTypes.any,
  addCustomerContact: PropTypes.any,
  cisUserInfo: PropTypes.any,
  isMtposStockAvailable: PropTypes.any,
  setAcknowledgeNotification: PropTypes.any,
  clearDeliveryData: PropTypes.any,
  subscriberData: PropTypes.any,
  simOnlyIPP: PropTypes.any,
  isRedVoucherEligible: PropTypes.any,
  roiValidatedData: PropTypes.any,
  userInformation: PropTypes.any,
  tradeInCIDValue: PropTypes.any,
  setBibDisplayData: PropTypes.any,
  bib: PropTypes.any
};

/* istanbul ignore next */
const mapStateToProps = state => {
  const {
    auth,
    addons,
    cart,
    order,
    plan,
    user,
    product,
    promotions,
    fulfillment,
    voucher,
    accessory,
    bridge,
    checkout,
    iphone
  } = state;

  const { mtposStock } = fulfillment;
  const deviceSku = cart?.order?.mobile?.device?.sku;
  const subscriberData =
    user?.information?.clientContext?.contactSubscriptionDetails;

  const { tradeInCID: tradeInCIDValue } = getTradeInDetails(state);

  return {
    isRedVoucherEligible:
      isRedMember(state) && isSimOnlyIPP(state) && isNewSignUp(state),
    simOnlyIPP: isSimOnlyIPP(state),
    customerId: user?.information?.clientContext?.customers?.[0]?.customerId,
    contact: user.information?.clientContext?.contact,
    contactId: user?.information?.clientContext?.contact?.contactId,
    subscriberData,
    cisUserInfo: user?.cis?.information,
    voucher,
    accessory,
    addons,
    cart,
    order,
    plan,
    checkoutFlow: checkout?.checkoutFlow,
    user,
    product,
    auth,
    bridge,
    isMtposStockAvailable:
      mtposStock?.data?.find(ms => ms.itemCode === deviceSku)?.availableQty > 0,
    promoOrderDetails: promotions.promoOrderDetails || {},
    selectedAddons: addons.selectedAddons,
    availableAddons: addons.availableAddons,
    roiValidatedData: iphone.validateSkuData,
    userInformation: user?.information,
    bib: cart?.order?.bib,
    tradeInCIDValue,
    specialPromo: promotions?.specialPromo
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const { addTradeInToOrder, removeAddonFromOrder } = addonsActions;
  const { getShoppingCartData, emptyCart } = cartActions;
  const { checkout } = checkoutActions;
  const { isEBillMethod } = fulFillmentActions;
  const { getAuthToken, resetWorryFree } = authActions;
  const {
    updateOrderToRunRules,
    removeAccessoriesFromOrder,
    updateMainLineNumber,
    addCustomerContact
  } = orderActions;
  const { getUserInformation, setSelectedService } = userActions;
  const {
    addVouchers,
    removeVouchers,
    getAllVouchers,
    resetReservedVouchers,
    getVouchers
  } = voucherActions;

  return {
    getUserInformation: () => dispatch(getUserInformation()),
    removeAccessoriesFromOrder: options =>
      dispatch(removeAccessoriesFromOrder(options)),
    getAuthToken: options => dispatch(getAuthToken(options)),
    resetWorryFree: () => dispatch(resetWorryFree()),
    getShoppingCartData: options => dispatch(getShoppingCartData(options)),
    addTradeInToOrder: options => dispatch(addTradeInToOrder(options)),
    removeAddonFromOrder: options => dispatch(removeAddonFromOrder(options)),
    updateOrderToRunRules: options => dispatch(updateOrderToRunRules(options)),
    emptyCart: options => dispatch(emptyCart(options)),
    checkout: options => dispatch(checkout(options)),
    updateAddons: options =>
      dispatch({
        type: 'ADDONS_FETCH_SUCCESS',
        value: options
      }),
    resetVouchers: () => dispatch({ type: 'RESET_VOUCHERS' }),
    addCustomerContact: options => dispatch(addCustomerContact(options)),
    resetReservedVouchers: options => dispatch(resetReservedVouchers(options)),
    getAvailableVouchers: options => dispatch(getVouchers(options)),
    getAllVouchers: options => dispatch(getAllVouchers(options)),
    addVouchers: options => dispatch(addVouchers(options)),
    removeVouchers: options => dispatch(removeVouchers(options)),
    getBillingPreference: payload => dispatch(isEBillMethod(payload)),
    clearCheckoutError: () => dispatch({ type: 'CLEAR_CHECKOUT_ERROR' }),
    resetModifyAccessories: () =>
      dispatch({ type: 'RESET_MODIFY_ACCESSORIES' }),
    resetModifyAddons: () => dispatch({ type: 'RESET_MODIFY_ADDON' }),
    updateMainLineNumber: options => dispatch(updateMainLineNumber(options)),
    setSelectedService: service => dispatch(setSelectedService(service)),
    setAcknowledgeNotification: () =>
      dispatch({ type: 'ACKNOWLEDGE_NOTIFICATION' }),
    clearDeliveryData: () => dispatch({ type: 'CLEAR_DELIVERY' }),
    setBibDisplayData: data => dispatch(setBibDisplayData(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
