import {
  CCE_IPHONE_FLOW,
  CIS_FLOW,
  CP9_FLOW,
  DTCP,
  ENABLE_MANUAL_FLOW,
  FEATURE_FLAG_ALLOW_SUBSEQUENT_ALLOCATE_API,
  FEATURE_FLAG_ENABLE_5GSA,
  FEATURE_FLAG_ENABLE_BIB,
  FEATURE_FLAG_ENABLE_QUALTRICS,
  FEATURE_FLAG_ENABLE_RRP,
  FEATURE_FLAG_ENABLE_TRADEIN_FOR_SOP,
  FEATURE_FLAG_MTPOS_FS_API_MIGRATION,
  FEATURE_FLAG_OFFER_TYPE_SIMONLY,
  FEATURE_FLAG_ELASTIC_SEARCH,
  FEATURE_FLAG_SPECIAL_PROMO,
  FEATURE_RECON_PD_UPDATE,
  FULLSTORY_ENABLE,
  GATEMANAGER,
  GOMO_FLOW,
  LI_HOOK_SIGNUP,
  NEW_PROMO_BANNER,
  ONLINE_STOCK_FROM_ESHOP,
  PLAN_GROUP_TABS,
  RED_PHASE,
  RRP_FLOW,
  SHOP_THANK_YOU_PAGE,
  SIMONLYPLUS_UPSELL_DESIGN,
  TOMO_PHASE_TWO,
  TRADEIN,
  UPDATE_PARTIAL_CONTACT,
  VOUCHER,
  YUU_MEMBERSHIP
} from '../types/featureFlag.types';

const clientFeatureFlag = flag =>
  typeof window !== 'undefined' && window.localStorage.getItem(flag) === 'true';

const isClientFlagPresentInStaging = flag => {
  const isProduction =
    process.env.NODE_ENV === 'production' &&
    process.env.GATSBY_STAGING_ENV !== 'true';

  return (
    !isProduction &&
    typeof window !== 'undefined' &&
    window.localStorage.getItem(flag)
  );
};

/**
 * If we have feature flag enabled to continue with the Detox flow, otherwise we direct to SpringD
 */
const isFeatureFlagEnabled = flag => {
  switch (flag) {
    case ONLINE_STOCK_FROM_ESHOP:
      return /^true$/i.test(
        process.env.GATSBY_FEATURE_FLAG_ONLINE_STOCK_FROM_ESHOP
      );
    case GOMO_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_GOMO_FLOW);
    case PLAN_GROUP_TABS:
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_PLAN_GROUP_TABS);
    // This feature flag is no longer needed after the launch of PP2.0
    case SIMONLYPLUS_UPSELL_DESIGN:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(
        process.env.GATSBY_FEATURE_FLAG_SIMONLYPLUS_UPSELL_DESIGN
      );
    case FEATURE_FLAG_ENABLE_5GSA:
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ENABLE_5GSA);
    case TRADEIN:
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_TRADEIN);
    case VOUCHER:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_VOUCHER);
    case DTCP:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_DTCP);
    case FULLSTORY_ENABLE:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FULLSTORY_ENABLE);
    case CIS_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_CIS_FLOW);

    case SHOP_THANK_YOU_PAGE: {
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(
        process.env.GATSBY_FEATURE_FLAG_SHOP_THANK_YOU_PAGE
      );
    }

    case LI_HOOK_SIGNUP: {
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_LI_HOOK_SIGNUP);
    }

    case RED_PHASE: {
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_RED_PHASE);
    }

    case CP9_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_CP9_FLOW);

    case TOMO_PHASE_TWO: {
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_TOMO_PHASE_TWO);
    }

    case CCE_IPHONE_FLOW: {
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_CCE_IPHONE_FLOW);
    }

    case GATEMANAGER:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_APIGEE_GATE_MANAGER);

    case YUU_MEMBERSHIP:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_YUU_MEMBERSHIP);
    case RRP_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_RRP_FLOW);

    case UPDATE_PARTIAL_CONTACT:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(
        process.env.GATSBY_FEATURE_FLAG_UPDATE_PARTIAL_CONTACT
      );

    case FEATURE_FLAG_ENABLE_RRP:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ENABLE_RRP);

    case ENABLE_MANUAL_FLOW:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ENABLE_MANUAL_FLOW);

    case FEATURE_FLAG_ENABLE_QUALTRICS:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ENABLE_QUALTRICS);
    case FEATURE_RECON_PD_UPDATE:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_RECON_PD_UPDATE);

    case FEATURE_FLAG_ENABLE_TRADEIN_FOR_SOP:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(
        process.env.GATSBY_FEATURE_FLAG_ENABLE_TRADEIN_FOR_SOP
      );

    case FEATURE_FLAG_ENABLE_BIB:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ENABLE_BIB);

    case NEW_PROMO_BANNER:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_NEW_PROMO_BANNER);

    case FEATURE_FLAG_ALLOW_SUBSEQUENT_ALLOCATE_API:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(
        process.env.GATSBY_FEATURE_FLAG_ALLOW_SUBSEQUENT_ALLOCATE_API
      );

    case FEATURE_FLAG_OFFER_TYPE_SIMONLY:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_OFFER_TYPE_SIMONLY);

    case FEATURE_FLAG_MTPOS_FS_API_MIGRATION:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_MTPOS_FS_API_MIGRATION);

    case FEATURE_FLAG_SPECIAL_PROMO:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_SPECIAL_PROMO);
    case FEATURE_FLAG_ELASTIC_SEARCH:
      if (isClientFlagPresentInStaging(flag)) {
        return clientFeatureFlag(flag);
      }
      return /^true$/i.test(process.env.GATSBY_FEATURE_FLAG_ELASTIC_SEARCH);
    default:
      return false;
  }
};

export default isFeatureFlagEnabled;
