import React from 'react';
import { Carousel, Modal, Text } from '@dls/web';

type PropTypes = {
  banners: Banner[];
};

export type Banner = {
  title: string;
  description: string;
  theme: 'light' | 'dark';
  sectionTitle: string;
  sectionDescription: string;
  ctaText: string;
  ctaLink: string;
  desktopFileReference: string;
  mobileFileReference: string;
  enableCTA: boolean;
  flowType?: string[];
  enablePromoBanner: boolean;
};

export const BannerList = (props: PropTypes) => {
  const { banners } = props;
  const [modalData, setModalData] = React.useState({
    modalOpen: false,
    modalTitle: '',
    modalDescription: ''
  });

  const shouldOpenModal = (banner: Banner): boolean => {
    return (
      !banner.ctaLink &&
      banner.enableCTA &&
      (banner.sectionTitle || banner.sectionDescription)
    );
  };

  const openLink = (ctaLink: string) => {
    window.open(ctaLink, '_self');
  };

  const onBannerClicked = (banner: Banner) => {
    if (shouldOpenModal(banner)) {
      setModalData({
        modalOpen: true,
        modalTitle: banner.sectionTitle,
        modalDescription: banner.sectionDescription
      });
      return;
    }

    if (banner.ctaLink) {
      openLink(banner.ctaLink);
    }
  };

  const renderModal = () => {
    return (
      <Modal
        visible={modalData.modalOpen}
        title={modalData.modalTitle}
        onClose={() => setModalData({ ...modalData, modalOpen: false })}
      >
        <Modal.Content>
          <Text type="body">
            <p
              dangerouslySetInnerHTML={{ __html: modalData.modalDescription }}
            />
          </Text>
        </Modal.Content>
      </Modal>
    );
  };
  const renderBanner = () => {
    return banners.map(banner => {
      return (
        <Carousel.CarouselContent
          imageRatio="1:2"
          desktopImage={banner.desktopFileReference}
          mobileImage={banner.mobileFileReference}
          title={banner.title}
          body={banner.description}
          buttonText={banner.enableCTA && banner.ctaText}
          inverse={banner.theme === 'light'}
          onClick={() => {
            onBannerClicked(banner);
          }}
          position="left"
        />
      );
    });
  };
  return (
    <>
      <Carousel fullWidth type="image">
        {renderBanner()}
      </Carousel>
      {renderModal()}
    </>
  );
};
