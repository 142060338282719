import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  Row,
  Column,
  Text,
  TextLink,
  Button,
  Spacing,
  useDevice
} from '@dls/web';
import {
  Support,
  Store,
  Contract,
  PhoneContact
} from '@dls/assets/dist/icons/web';
import theme from '../../theme';

const CommunicationPanel = styled.div`
  ${p => `
  padding: ${theme.spacing(3)} 0 0 0;
  margin-top:${theme.spacing(2)}
  `};
  height: 100%;
  border-left: none;
  border-top: 2px solid ${theme.colours.grey_200};

  @media (min-width: 62em) {
    border-left: 2px solid ${theme.colours.grey_200};
    border-top: none;
    ${p => `
      padding: 0 0 0 ${theme.spacing(4)};
      margin-top:0;
  `};
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: inline-block;
  @media (min-width: 62em) {
    display: inline-block;
    width: auto;
  }
`;

export function renderItem(type) {
  switch (type) {
    case 'Support':
      return Support;
    case 'Store':
      return Store;
    case 'Contract':
      return Contract;
    case 'PhoneContact':
      return PhoneContact;
    default:
      return null;
  }
}

const FooterLink = ({ link, text, type }) => {
  const { isDesktop, isTV } = useDevice();
  const wideModal = isDesktop || isTV;

  const showOnlyTextWithoutLink = type === 'PhoneContact' && wideModal;
  const footerIcon = renderItem(type);
  return (
    <>
      {showOnlyTextWithoutLink ? (
        <Text>{text}</Text>
      ) : (
        <TextLink href={link}>
          {footerIcon && <TextLink.Icon icon={footerIcon} />}
          <TextLink.Text>{text}</TextLink.Text>
        </TextLink>
      )}
    </>
  );
};

const GenericError = props => {
  const {
    title,
    errorText,
    primaryButtonText,
    secondaryButtonText,
    typeLinkText,
    onPrimaryClick,
    onSecondaryClick,
    footerList
  } = props;
  return (
    <Grid>
      <Row between="md" data-testid="generic-error-page">
        <Column xs={12} md={7}>
          <Text type="pageTitle">{title}</Text>
          <Spacing top={3} bottom={4}>
            <Text>{errorText}</Text>
          </Spacing>
          <ButtonContainer>
            {primaryButtonText && (
              <Button
                data-testid={`generic-error-primary-button`}
                lg={false}
                primary
                fullWidth
                tabIndex={0}
                onClick={onPrimaryClick}
              >
                {primaryButtonText}
              </Button>
            )}
            {secondaryButtonText && (
              <Button
                lg={false}
                secondary
                fullWidth
                tabIndex={0}
                onClick={onSecondaryClick}
              >
                {secondaryButtonText}
              </Button>
            )}
            {typeLinkText && <Text type="link">{typeLinkText}</Text>}
          </ButtonContainer>
        </Column>
        <Column xs={12} md={5}>
          {footerList && (
            <CommunicationPanel>
              <Text>You could also</Text>
              {footerList.map(item => (
                <Spacing top={2} data-testid="footer-section" key={item.type}>
                  <FooterLink
                    link={item.link}
                    text={item.label}
                    type={item.type}
                  />
                </Spacing>
              ))}
            </CommunicationPanel>
          )}
        </Column>
      </Row>
    </Grid>
  );
};

export default GenericError;
