export const SIMONLYPLUS_UPSELL_DESIGN = 'SIMONLYPLUS_UPSELL_DESIGN';
export const GOMO_FLOW = 'GOMO_FLOW';
export const ONLINE_STOCK_FROM_ESHOP = 'ONLINE_STOCK_FROM_ESHOP';
export const PLAN_GROUP_TABS = 'PLAN_GROUP_TABS';
export const FEATURE_FLAG_ENABLE_5GSA = 'FEATURE_FLAG_ENABLE_5GSA';
export const VOUCHER = 'VOUCHER';
export const TRADEIN = 'TRADEIN';
export const SHOP_THANK_YOU_PAGE = 'SHOP_THANK_YOU_PAGE';
export const CIS_FLOW = 'CIS_FLOW';
export const DTCP = 'DTCP';
export const FULLSTORY_ENABLE = 'FULLSTORY_ENABLE';
export const LI_HOOK_SIGNUP = 'LI_HOOK_SIGNUP';
export const RED_PHASE = 'RED_PHASE';
export const CP9_FLOW = 'CP9_FLOW';
export const TOMO_PHASE_TWO = 'TOMO_PHASE_TWO';
export const GATEMANAGER = 'GATEMANAGER';
export const CCE_IPHONE_FLOW = 'CCE_IPHONE_FLOW';
export const YUU_MEMBERSHIP = 'YUU_MEMBERSHIP';
export const RRP_FLOW = 'RRP_FLOW';
export const UPDATE_PARTIAL_CONTACT = 'UPDATE_PARTIAL_CONTACT';
export const ENABLE_MANUAL_FLOW = 'ENABLE_MANUAL_FLOW';
export const FEATURE_FLAG_ENABLE_QUALTRICS = 'FEATURE_FLAG_ENABLE_QUALTRICS';
export const FEATURE_FLAG_ENABLE_RRP = 'FEATURE_FLAG_ENABLE_RRP';
export const FEATURE_RECON_PD_UPDATE = 'FEATURE_RECON_PD_UPDATE';
export const FEATURE_FLAG_ENABLE_TRADEIN_FOR_SOP =
  'FEATURE_FLAG_ENABLE_TRADEIN_FOR_SOP';
export const FEATURE_FLAG_ENABLE_BIB = 'FEATURE_FLAG_ENABLE_BIB';
export const NEW_PROMO_BANNER = 'NEW_PROMO_BANNER';
export const FEATURE_FLAG_ALLOW_SUBSEQUENT_ALLOCATE_API =
  'FEATURE_FLAG_ALLOW_SUBSEQUENT_ALLOCATE_API';
export const FEATURE_FLAG_OFFER_TYPE_SIMONLY =
  'FEATURE_FLAG_OFFER_TYPE_SIMONLY';
export const FEATURE_FLAG_MTPOS_FS_API_MIGRATION =
  'FEATURE_FLAG_MTPOS_FS_API_MIGRATION';
export const FEATURE_FLAG_SPECIAL_PROMO = 'FEATURE_FLAG_SPECIAL_PROMO';
export const FEATURE_FLAG_ELASTIC_SEARCH = 'FEATURE_FLAG_ELASTIC_SEARCH';
