/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Row,
  Column,
  Text,
  Divider,
  Selector,
  Spacing,
  Button,
  withTheme,
  useDevice
} from '@dls/web';
import { Completed, Alert } from '@dls/assets/dist/icons/web';
import { useSelector, useDispatch } from 'react-redux';
import { checkoutActions, userActions } from '@detox/actions';
import useTranslate from '../../hooks/useTranslation';
import { FieldObject } from '../../types/registrationCheckout';
import Disclaimer from './Disclaimer';
import {
  CHOOSE_SIM_TYPE,
  ChooseSimType,
  SimTransactionType,
  SimTypeId,
  eSIMCompatibleTags
} from '../../constants/choose-sim-type';
import styled, { css } from 'styled-components';
import { scrollToSection } from '@common-modules/product-catalogue';
import { phoneTagsConfig } from '../../config/phone-config';
import { isNullOrUndefinedOrEmptyCheck, isTagged } from '../../helpers/common';
import CustomerPersonalDetails from './CustomerPersonalDetails';
import { KeyValue, APP_TYPE_ANY } from '../../types/common.types';
import { formattedPhoneNumber } from '../OrderSummary/ChangeContactDetails/helper';
import {
  FEATURE_RECON_PD_UPDATE,
  FEATURE_FLAG_SPECIAL_PROMO
} from '../../types/featureFlag.types';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import CONSTANTS from '../../constants/common';

interface SimTypeDesignConfig {
  dividerNeeded: boolean;
  titlesNeeded: boolean;
  ctaNeeded: boolean;
  typeHeight: string;
  simTypeColumnProps: FieldObject;
  forceCostFree: boolean;
}

interface TProps {
  data: ChooseSimType[];
  showSubtitle?: boolean;
  updateSimTypeOnSelection?: boolean;
  preSelectedSimType?: SimTypeId;
  selectedProduct?: FieldObject;
  columnProps?: FieldObject;
  transactionTypeValue: SimTransactionType;
  simTypeDesignConfig?: Partial<SimTypeDesignConfig>;
  scrollToSimType?: boolean;
  onSimTypeOnSelection?: (selectedSimType: SimTypeId) => void;
  enablePDForm?: boolean;
}

const StyledRow = styled(Row)`
  margin: 0 -8px;
`;

const StyledEsimCompatibilityRow = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
`;

const StyledSpacing = withTheme(styled(Spacing)`
  ${({ coreTheme }) => css`
    height: ${({ typeHeight }) => `${typeHeight || 'auto'};`};
    @media (max-width: ${coreTheme.brk_sm}) {
      height: auto;
    }
  `}
`);

export const ChooseSIMType = (props: TProps): ReactElement => {
  const dispatch = useDispatch();
  const { getUserInformation } = userActions;
  const formikRef = useRef<KeyValue>();
  const { userInformation, specialPromo } = useSelector((state: KeyValue) => ({
    userInformation: state?.user?.information,
    specialPromo: state?.promotions?.specialPromo
  }));
  const { t } = useTranslate();
  const { isMobile } = useDevice();
  const {
    data,
    onSimTypeOnSelection,
    selectedProduct,
    columnProps,
    showSubtitle = false,
    transactionTypeValue = 'newSignup',
    simTypeDesignConfig,
    updateSimTypeOnSelection = false,
    preSelectedSimType = null,
    scrollToSimType = false,
    enablePDForm = false
  } = props;
  const imdaFeatureEnabled = isFeatureFlagEnabled(FEATURE_RECON_PD_UPDATE);
  const isSpecialPromoEnabled = isFeatureFlagEnabled(
    FEATURE_FLAG_SPECIAL_PROMO
  );
  const hasSpecialPromo = isSpecialPromoEnabled && specialPromo;
  const showCustomerPersonalDetails =
    imdaFeatureEnabled &&
    enablePDForm &&
    transactionTypeValue === CONSTANTS.TRANSACTION_TYPE_VALUE.RECONTRACT &&
    userInformation?.userDetails?.loginId;
  const {
    titlesNeeded = true,
    ctaNeeded = true,
    dividerNeeded = true,
    simTypeColumnProps = { xs: 12 },
    typeHeight,
    forceCostFree = false
  } = simTypeDesignConfig || {};
  const [selectedSimType, setSelectedSimType] = useState<SimTypeId>();

  const columnConfig = columnProps || { sm: 12, md: 8 };
  const isLifeStyleProduct = isTagged(
    selectedProduct?.tags || [],
    phoneTagsConfig['home-lifestyle'].tagName
  );
  const hasNoPhysicalSim = isTagged(
    selectedProduct?.tags || [],
    phoneTagsConfig['no-physical-sim'].tagName
  );

  let eSimData;

  const availableTypes = (data || [])
    .filter(availableType => {
      const { transactionType, simTypeId } = availableType;

      if (simTypeId === CHOOSE_SIM_TYPE.SIM_TYPE_ESIM) {
        eSimData = availableType;
      }

      if (
        simTypeId === CHOOSE_SIM_TYPE.SIM_TYPE_PhysicalSIM &&
        (hasNoPhysicalSim || hasSpecialPromo)
      ) {
        return false;
      }

      return !!transactionType[transactionTypeValue];
    })
    .sort((a, b) =>
      a.notAvailable === b.notAvailable ? 0 : a.notAvailable ? 1 : -1
    );

  const isESIMCompatible = (simSlotsperSIMCardType: string): boolean => {
    if (!simSlotsperSIMCardType) {
      return false;
    }

    return (
      Boolean(
        eSIMCompatibleTags.find(
          eSIMCompatibleTag => eSIMCompatibleTag === simSlotsperSIMCardType
        )
      ) || simSlotsperSIMCardType.includes('eSIM')
    );
  };

  const { simSlotsperSIMCardType, title: productTitle } = selectedProduct || {};
  const iseSIMCompatible = isESIMCompatible(simSlotsperSIMCardType);
  const shouldShowCompatibility =
    selectedProduct &&
    !isLifeStyleProduct &&
    isNullOrUndefinedOrEmptyCheck(simSlotsperSIMCardType, true) &&
    simSlotsperSIMCardType !== 'NA';

  useEffect(() => {
    // set selected sim type
    if (preSelectedSimType) {
      setSelectedSimType(preSelectedSimType);
    } else if (!availableTypes[0]?.notAvailable) {
      setSelectedSimType(availableTypes[0]?.simTypeId);
      !ctaNeeded && onSimTypeOnSelection(availableTypes[0]?.simTypeId);
    }

    scrollToSimType && scrollToSection('choose-sim-type');
  }, []);

  if (availableTypes.length === 0) {
    return <></>;
  }

  const handleSimTypeSelection = async () => {
    if (showCustomerPersonalDetails) {
      try {
        await formikRef.current?.submitForm();
      } catch (e) {}

      const isFormValid = formikRef?.current?.isValid;
      const { email, contactId, phone } =
        userInformation.clientContext.contact || {};
      const formValues = {
        ...formikRef.current.values,
        number: formattedPhoneNumber(formikRef.current.values.number),
        email: formikRef.current.values?.email
          ? formikRef.current.values.email.toLowerCase()
          : ''
      };
      if (
        isFormValid &&
        (email.toLowerCase() !== formValues.email ||
          phone !== formValues.number)
      ) {
        const response: KeyValue = await (dispatch(
          checkoutActions.updateCustomerContact({
            phone: formValues.number,
            emailId: formValues.email,
            contactId
          })
        ) as APP_TYPE_ANY);
        if (response?.implContactPerson) {
          await (dispatch(getUserInformation()) as APP_TYPE_ANY);
        } else {
          return;
        }
      } else if (!isFormValid) {
        return;
      }
    }
    onSimTypeOnSelection(selectedSimType);
  };

  return (
    <Row id="choose-sim-type">
      <Column noGutter {...columnConfig}>
        {dividerNeeded && (
          <Spacing top={2} bottom={3}>
            <Divider />
          </Spacing>
        )}
        {titlesNeeded && (
          <Spacing bottom={1}>
            <Text type="header">{t('CHOOSE_SIM_TYPE')}</Text>
            {showSubtitle && (
              <Text type="body">{t('SIM_TYPE_COMPATIBLE')}</Text>
            )}
          </Spacing>
        )}

        <StyledRow>
          {availableTypes.map(simType => {
            const simTypePrice = simType.simPrice[transactionTypeValue];
            const simTypePriceAvailable =
              simTypePrice !== null &&
              simTypePrice !== undefined &&
              simTypePrice !== '';
            const simPriceValue = simTypePrice ?? null;

            return (
              <Column {...simTypeColumnProps} key={simType.simTypeId}>
                <StyledSpacing typeHeight={typeHeight}>
                  <Selector
                    value={simType.simTypeId}
                    selected={selectedSimType === simType.simTypeId}
                    onClick={v => {
                      setSelectedSimType(v);
                      if (updateSimTypeOnSelection) {
                        onSimTypeOnSelection(v);
                      }
                      showCustomerPersonalDetails &&
                        scrollToSection('pd-phone');
                    }}
                    disabled={simType.notAvailable}
                  >
                    <Selector.Body
                      title={simType.simTitle}
                      subtitle={
                        selectedProduct
                          ? simType.descWithDevice
                          : simType.descWithoutDevice
                      }
                      align={'start'}
                    />

                    {simTypePriceAvailable && !forceCostFree && (
                      <Text>
                        {simPriceValue === 0
                          ? t('PRICE_FREE')
                          : `$${simPriceValue}`}
                      </Text>
                    )}
                  </Selector>
                  {simType.notAvailable && simType.footerNotAvailable && (
                    <Text type="body">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: simType.footerNotAvailable
                        }}
                      />
                    </Text>
                  )}
                </StyledSpacing>
                {simType.simTypeId === CHOOSE_SIM_TYPE.SIM_TYPE_ESIM &&
                  shouldShowCompatibility &&
                  !simType.notAvailable && (
                    <Spacing top={isMobile ? 2 : 1}>
                      <StyledEsimCompatibilityRow>
                        <Spacing top={1} responsive={false}>
                          {iseSIMCompatible ? <Completed /> : <Alert />}
                        </Spacing>
                        <Spacing left={0.02}>
                          {iseSIMCompatible ? (
                            <Text>Your {productTitle} is eSIM-compatible</Text>
                          ) : (
                            <Text>
                              Your {productTitle} is not eSIM-compatible
                            </Text>
                          )}
                        </Spacing>
                      </StyledEsimCompatibilityRow>
                    </Spacing>
                  )}
              </Column>
            );
          })}
        </StyledRow>
        {selectedSimType === CHOOSE_SIM_TYPE.SIM_TYPE_ESIM && eSimData && (
          <Spacing top={1} bottom={isMobile ? 1 : 0}>
            <Disclaimer data={eSimData} isWithDevice={!!selectedProduct} />
          </Spacing>
        )}

        {showCustomerPersonalDetails && (
          <CustomerPersonalDetails
            formikRef={formikRef}
            userInformation={{ ...userInformation.clientContext.contact }}
          />
        )}

        {ctaNeeded && (
          <Spacing top={2} bottom={4}>
            <Button
              fullWidth={isMobile}
              primary
              onClick={() => handleSimTypeSelection()}
              data-testid="btn-choose-addons"
            >
              <Spacing leftRight={4}>{t('CHOOSE_ADD_ONS')}</Spacing>
            </Button>
          </Spacing>
        )}
      </Column>
    </Row>
  );
};

export default ChooseSIMType;
