/* istanbul ignore file */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import { fulFillmentActions, api, PaymentMode } from '@detox/actions';

import { Fulfilmentv2 } from '@common-modules/shopping-cart';
import { navigation } from '../../../middlewares/navigation-constants';

import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { trans as t } from '../../../helpers/localisation';
import { APP_TYPE_ANY, KeyValue } from '../../../types/common.types';
import {
  getDeliveryTimeSlot,
  getPopstationTimeSlot,
  getDoorStepDeliveryTimeSlot,
  getSelectedPopStationTimeSlot
} from '../../Fulfilment/helpers';
import {
  formatNumber,
  getFirstEntries,
  flattenNodes,
  dataLayerPush
} from '../../../helpers/common';
import { FULFILLMENT } from '../../../constants';
import {
  POPSTATION_TIMESLOTS,
  DELIVERY_SLOTS
} from '../../../config/fulfilment';
import { ACTION_TYPES } from '../../../constants/actions';
import {
  PICKUP_TYPE_TO_KEY,
  PICKUP_TYPE_API_TO_UI
} from '../../../constants/fulfillment';
import CONSTANTS from '../../../constants/checkout';
import LoadingOverlayComponent from '../../../components/LoadingOverlay';
import { formatDate } from '../../../components/Fulfilment/helpers';
import {
  getFulfilmentOptions,
  RRPFulfilment,
  formattedDate,
  getStartEndDateEpoch,
  formatFloorUnitNumber,
  getFloorUnit
} from '../../../helpers/fulfilment';
import slugify from '../../../helpers/slugify';
import {
  getLeadDays,
  getPreOrderFulfilment,
  getAEMFulfilmentById
} from '../../../helpers/fulfilment.common';
import { rrpReviewOrderDataLayer } from '../../../helpers/dataLayerHelpers';
import { SkeletonType } from '../../SkeletonLoader';
import { useLoader } from '../../../hooks/useLoader';
import { useFlowCheck } from '../../../hooks/useFlowCheck/useFlowCheck';

const { FULFILMENT_TYPES_AEM } = FULFILLMENT;

interface Fulfilmentconfigs {
  isLoading: boolean;
  hasError: boolean;
  showFulfilment: boolean;
  errorTitle: string | React.ReactElement;
  errorMessage: string | React.ReactElement;
}

interface ListItem {
  name: string;
  description: string;
  id: string;
  imageSrc: string;
  quantity: string;
  ribbon: string;
  tags: string[];
  vendor: string;
}

const Delivery = ({ data, orderDetails }: KeyValue): React.ReactElement => {
  const {
    apiRetrieveLogisticAppointmentsSlots,
    getPopstations,
    apiSelfCollectionTimeSlots,
    rrpSetDeliveryDetails,
    rrpReserveLogisticsAppointment
  } = api.mcss;

  const dispatch = useDispatch();
  const allRrpProduct = flattenNodes(data.allRrpProduct);
  const { fulfilmentList, leadDays = [] } = data.rrpFulfilment;
  const aemFulfilmentById = getAEMFulfilmentById(fulfilmentList, 'fulfilment');
  const orderModelRef = useRef<Array<KeyValue>>([]);
  const actualDeliverySlots = useRef<KeyValue>({});
  const [fulfilmentConfigs, setFulfilmentConfigs] = useState<Fulfilmentconfigs>(
    {
      isLoading: false,
      hasError: false,
      showFulfilment: false,
      errorTitle: '',
      errorMessage: ''
    }
  );

  const preOrderFulfilment = useMemo(() => {
    let skuIds: string[] = [];

    const sintelBag = orderModelRef.current?.find(
      data => data.items.vendor === RRPFulfilment.vendor.SINGTEL
    );

    if (sintelBag) {
      skuIds = sintelBag.items.list.map((item: ListItem) => item.id);
    }

    return getPreOrderFulfilment(skuIds, leadDays);
  }, [orderModelRef.current]);

  const addressFormRef = useRef<{
    configs: {
      preSelection?: {
        postCode: string;
      };
    };
    address: KeyValue;
  }>();

  const handleErrorModalClose = () => {
    setFulfilmentConfigs((prevState: Fulfilmentconfigs) => ({
      ...prevState,
      hasError: false,
      isLoading: false
    }));
  };

  const { renderError } = useErrorHandler({
    states: [{ ...fulfilmentConfigs, redirectNeeded: false } as APP_TYPE_ANY],
    onCloseErrorPopUp: handleErrorModalClose
  });

  const { rrpCheckout, orderModel, addressInfo, rrpCart } = useSelector<
    {
      fulfillment: KeyValue;
      cart: { rrpOrder: KeyValue };
      rrpCheckout: { orderModel: KeyValue };
      rrpCart: KeyValue;
    },
    KeyValue
  >(state => ({
    rrpCheckout: state?.rrpCheckout,
    orderDetails: state?.cart?.rrpOrder,
    orderModel: state?.rrpCheckout?.orderModel,
    addressInfo: state?.fulfillment?.addressInfo,
    rrpCart: state?.rrpCart
  }));
  useFlowCheck({ flowData: [rrpCart?.rrpOrder] });
  const { Loader } = useLoader({
    options: { type: SkeletonType.page },
    states: [rrpCart, fulfilmentConfigs, addressInfo]
  });

  const variantBySku = useMemo(() => {
    return allRrpProduct.reduce((acc, cur) => {
      cur.variants.forEach(variant => {
        const { sku, ...data } = variant;
        if (!(sku in acc)) {
          acc[sku] = {
            ...data,
            maxAllowedQuantity: cur.maxAllowedQuantity,
            tags: cur.tags,
            shippingLiner: cur?.shippingLiner
          };
        }
      });
      return acc;
    }, {});
  }, [allRrpProduct]);

  const handleRetrieveAddress = async (postcode: string) => {
    addressFormRef.current = null;
    dispatch(fulFillmentActions.getAddressInfo(postcode));
  };

  const getProductsList = (bag: KeyValue) => {
    const products = bag.orderItems;
    const productList = products.map((item: KeyValue) => {
      let productVariantDetails;
      if (item.sku in variantBySku) {
        productVariantDetails = variantBySku[item.sku];
      }
      return {
        id: item.sku,
        imageSrc:
          process.env.GATSBY_AEM_URL + productVariantDetails?.smallImage?.src,
        name: `${item?.productDetails?.title}, ${item?.productDetails?.color}`,
        description: productVariantDetails?.shippingLiner,
        quantity: item?.quantity && formatNumber(item.quantity),
        ribbon: productVariantDetails?.ribbonText
          ? `[${productVariantDetails?.ribbonText}]`
          : '',
        vendor: bag.vendor,
        tags: productVariantDetails?.tags
      };
    });
    return productList;
  };

  const handleSearchPopstation = async (postcode, orderId) => {
    setFulfilmentConfigs((prevState: Fulfilmentconfigs) => ({
      ...prevState,
      isLoading: true
    }));
    const popStations = await getPopstations({ postcode });
    const orderIndex = orderModelRef.current.findIndex(
      x => x.orderId === orderId
    );
    orderModelRef.current[orderIndex] = {
      ...orderModelRef.current[orderIndex],
      methods: {
        ...orderModelRef.current[orderIndex]?.methods,
        data: {
          ...orderModelRef.current[orderIndex]?.methods?.data,
          popStations: popStations?.implPOPStationDetails
        }
      }
    };

    dispatch({
      type: ACTION_TYPES.RRP.SET_FULFILMENT_STATE,
      data: { orderModel: orderModelRef.current }
    });
    setFulfilmentConfigs((prevState: Fulfilmentconfigs) => ({
      ...prevState,
      isLoading: false
    }));
    return popStations;
  };

  const onPickupChange = (orderId: string, pickupType: string) => {
    const orderIndex = orderModelRef.current.findIndex(
      x => x.orderId === orderId
    );

    if (
      pickupType === PICKUP_TYPE_TO_KEY.POPSTATION &&
      orderModelRef.current.length <= 1
    ) {
      dispatch({ type: CONSTANTS.RESET_ADDRESS_DATA });
    }

    const { selectedDeliveryMode } =
      orderModelRef.current[orderIndex]?.methods?.configs?.preSelection || {};
    if (selectedDeliveryMode && selectedDeliveryMode !== pickupType) {
      orderModelRef.current[orderIndex] = {
        ...orderModelRef.current[orderIndex],
        methods: {
          ...orderModelRef.current[orderIndex]?.methods,
          configs: {}
        }
      };
    }
  };

  const validatePopstationPreSelectionConfigs = (
    selectedOrder: KeyValue,
    deliverySlots: KeyValue
  ) => {
    const { timeslotEpoch, address, postCode } =
      selectedOrder?.methods?.configs?.preSelection || {};
    if (!timeslotEpoch || !address || !postCode) {
      return { configs: {} };
    }
    const popStationExists = selectedOrder?.methods?.data?.popStations.find(
      popstation =>
        popstation.KioskId === address && popstation.ZipCode === postCode
    );
    const timeSlot = formatDate(
      new Date(timeslotEpoch),
      RRPFulfilment.slotDateFormat.POPSTATION_TIMESLOT
    );
    const timeSlotExists = deliverySlots.includes(timeSlot);
    if (popStationExists && timeSlotExists) {
      return {};
    }
    return { configs: {} };
  };

  const handleSearchPopstationTimeslot = async (
    value: string,
    bagId: string
  ) => {
    const timeslots = await apiSelfCollectionTimeSlots({
      slotType: RRPFulfilment.slotTypes.RRP,
      hasPrelaunchDevice: Boolean(preOrderFulfilment.firstDeliveryDate),
      cdf: '',
      documentRequired: false,
      dealer: RRPFulfilment.dealerCode,
      outlet: value,
      ld: getLeadDays({
        firstDeliveryDate: preOrderFulfilment.firstDeliveryDate,
        fulfilmentData: aemFulfilmentById[FULFILMENT_TYPES_AEM.POP_STATION]
      })
    });

    const modTimeSlots = getFirstEntries(
      getPopstationTimeSlot(timeslots),
      POPSTATION_TIMESLOTS
    );

    const orderIndex = orderModelRef.current.findIndex(
      x => x.orderId === bagId
    );
    orderModelRef.current[orderIndex] = {
      ...orderModelRef.current[orderIndex],
      methods: {
        ...orderModelRef.current[orderIndex]?.methods,
        data: {
          ...orderModelRef.current[orderIndex]?.methods?.data,
          popstationTimeslots: modTimeSlots,
          timeSlotsActual: timeslots
        },
        ...validatePopstationPreSelectionConfigs(
          orderModelRef.current[orderIndex],
          modTimeSlots
        )
      }
    };

    dispatch({
      type: ACTION_TYPES.RRP.SET_FULFILMENT_STATE,
      data: { orderModel: orderModelRef.current }
    });
  };

  const getSlotType = (orderId: string) => {
    const bag = orderModelRef.current.find(order => order.orderId === orderId);
    if (bag?.items?.vendor === RRPFulfilment.vendor.SINGTEL) {
      return RRPFulfilment.slotTypes.NonDropShipping;
    } else {
      return bag.items?.list.reduce(
        (accumulator: string, currentValue: KeyValue, index: number) =>
          `${accumulator}${index > 0 ? ',' : ''}${currentValue.id}`,
        `${RRPFulfilment.slotTypes.DropShipping}-`
      );
    }
  };

  const validateDeliveryPreSelectionConfigs = (
    selectedOrder: KeyValue,
    deliverySlots: KeyValue
  ) => {
    const { selectedTime, timeslot } =
      selectedOrder?.methods?.configs?.preSelection || {};
    if (!timeslot) {
      return { configs: {} };
    }
    if (
      deliverySlots?.[timeslot] &&
      !deliverySlots?.[timeslot]?.includes(selectedTime)
    ) {
      return { configs: {} };
    }

    return {};
  };

  const getDeliverySlots = async (orderId: string) => {
    try {
      const rrpFullPriceSlotType = getSlotType(orderId);
      const deliverySlots = await apiRetrieveLogisticAppointmentsSlots({
        slotType: RRPFulfilment.slotTypes.RRP,
        rrpFullPriceSlotType,
        dealerCode: RRPFulfilment.dealerCode,
        hasPrelaunchDevice: Boolean(preOrderFulfilment.firstDeliveryDate),
        leadDay: getLeadDays({
          firstDeliveryDate: preOrderFulfilment.firstDeliveryDate,
          fulfilmentData:
            aemFulfilmentById[FULFILMENT_TYPES_AEM.DOOR_STEP_DELIVERY]
        }),
        paymentMode: RRPFulfilment.paymentMode.CASH as PaymentMode
      });

      actualDeliverySlots.current = {
        ...actualDeliverySlots.current,
        [orderId]: deliverySlots?.slots
      };

      if (!deliverySlots?.slots?.length) {
        setFulfilmentConfigs((prevState: Fulfilmentconfigs) => ({
          ...prevState,
          errorTitle: t('TIME_SLOTS_UNAVAILABLE'),
          errorMessage: t('TIME_SLOTS_UNAVAILABLE_MSG'),
          hasError: true,
          isLoading: false
        }));
        return null;
      }

      const enabledDays = getFirstEntries(
        getDeliveryTimeSlot(deliverySlots?.slots),
        DELIVERY_SLOTS
      );

      const orderIndex = orderModelRef.current.findIndex(
        x => x.orderId === orderId
      );
      orderModelRef.current[orderIndex] = {
        ...orderModelRef.current[orderIndex],
        methods: {
          ...orderModelRef.current[orderIndex]?.methods,
          data: {
            ...orderModelRef.current[orderIndex]?.methods?.data,
            deliverySlots: enabledDays,
            popstationTimeslots: [],
            popStations: []
          },
          ...validateDeliveryPreSelectionConfigs(
            orderModelRef.current[orderIndex],
            enabledDays
          )
        }
      };

      dispatch({
        type: ACTION_TYPES.RRP.SET_FULFILMENT_STATE,
        data: { orderModel: orderModelRef.current }
      });

      return deliverySlots;
    } catch (e) {
      setFulfilmentConfigs((prevState: Fulfilmentconfigs) => ({
        ...prevState,
        hasError: true,
        isLoading: false
      }));
    }
  };

  useEffect(() => {
    if (orderDetails?.ImplRRPOrderDetails?.length) {
      const orders = orderDetails.ImplRRPOrderDetails.map(
        (order: KeyValue, index: number) => {
          const productsList = getProductsList(order);
          const pickupOptions = getFulfilmentOptions({
            bag: productsList,
            vendor: order.vendor,
            fulfilmentData: data?.rrpFulfilment
          });

          const deliveryDetails = order?.orderDeliveryDetails;
          const selectedDeliveryMode = deliveryDetails?.deliveryMethod
            ? PICKUP_TYPE_API_TO_UI[
                slugify(order?.orderDeliveryDetails?.deliveryMethod)
              ]
            : '';

          const selectedDeliveryExistsInPickupOption = pickupOptions.find(
            pickup => pickup.type === selectedDeliveryMode && !pickup.disabled
          );
          let configs = {};
          if (
            order?.orderDeliveryDetails &&
            selectedDeliveryMode &&
            selectedDeliveryExistsInPickupOption
          ) {
            const postCode = deliveryDetails?.deliveryAddress?.postcode;
            const timeslot = deliveryDetails?.slotStartDateEpoch
              ? formatDate(new Date(deliveryDetails?.slotStartDateEpoch))
              : '';
            const selectedTime = deliveryDetails?.timeDescription ?? '';
            configs = {
              preSelection: {
                postCode,
                address: deliveryDetails.popStationCode,
                timeslot,
                timeslotEpoch: deliveryDetails?.slotStartDateEpoch,
                selectedTime,
                selectedDeliveryMode
              }
            };
          }

          return {
            title: {
              text: t('BAG_NO', {
                number: formatNumber(index + 1)
              })
            },
            orderId: order.orderId,
            items: { list: productsList, vendor: order?.vendor },
            methods: {
              pickupOptions,
              data: {
                deliverySlots: null,
                popStations: [],
                popstationTimeslots: []
              },
              callbacks: {
                onPostalCodeSearch: (orderId: string, postalCode: string) => {
                  return handleSearchPopstation(postalCode, orderId);
                },
                getDeliverySlots,
                getPopstationTimeslot: handleSearchPopstationTimeslot,
                onPickupChange
              },
              configs
            }
          };
        }
      );

      orderModelRef.current = orders;
      dispatch({
        type: ACTION_TYPES.RRP.SET_FULFILMENT_STATE,
        data: { orderModel: orderModelRef.current }
      });
    }

    const deliveryAddress = orderDetails?.ImplRRPOrderDetails.find(
      (order: APP_TYPE_ANY) =>
        order?.orderDeliveryDetails?.deliveryMethod ===
          RRPFulfilment.deliveryMethodApi[
            PICKUP_TYPE_TO_KEY.DOORSTEP_DELVIERY
          ] ||
        order?.orderDeliveryDetails?.deliveryMethod ===
          RRPFulfilment.deliveryMethodApi[PICKUP_TYPE_TO_KEY.PARTNER_DELIVERY]
    )?.orderDeliveryDetails?.deliveryAddress;

    if (deliveryAddress) {
      if (deliveryAddress?.postcode) {
        dispatch(fulFillmentActions.getAddressInfo(deliveryAddress?.postcode));
      }

      const { floor, unitNumber } = getFloorUnit(deliveryAddress?.floor);
      addressFormRef.current = {
        configs: {
          preSelection: {
            postCode: deliveryAddress?.postcode
          }
        },
        address: {
          block: deliveryAddress?.block,
          postcode: deliveryAddress?.postcode,
          blockOrHouseAddr: deliveryAddress?.blockOrHouseAddr,
          blockOrHouseNum: deliveryAddress?.blockOrHouseNum,
          buildingName: deliveryAddress?.buildingName,
          country: deliveryAddress?.country,
          floor,
          streetName: deliveryAddress?.streetName,
          unitNumber
        }
      };
    } else {
      addressFormRef.current = {
        configs: {},
        address: {}
      };
    }
  }, [orderDetails?.ImplRRPOrderDetails]);

  useEffect(() => {
    const orderModelCallbacks = orderModel?.[0]?.methods?.callbacks;
    if (
      orderModelRef &&
      orderModelCallbacks &&
      Object.keys(orderModelCallbacks).length
    ) {
      setFulfilmentConfigs({ ...fulfilmentConfigs, showFulfilment: true });
    }
  }, [orderModel]);

  const reserveLogistics = async (orders: Array<KeyValue>) => {
    let isReserveFailed = false;
    for (const order of orders) {
      const payload = {
        action: 'R',
        slotType: getSlotType(order?.orderId),
        implTimeSlot: {
          slotCode: order?.slotCode,
          slotStartDate: order?.slotStartDate,
          popStationCode: order?.popStationCode,
          timeDescription: order?.timeDescription
        },
        msisdn: orderDetails?.customerDetails?.phone,
        slotQuantity: 1,
        paymentMode: RRPFulfilment.paymentMode.CASH,
        dealerCode: RRPFulfilment.dealerCode
      };
      const res = await rrpReserveLogisticsAppointment({
        rrpMasterOrderId: rrpCheckout.rrpMasterOrderId,
        rrpOrderId: order?.orderId,
        payload
      });

      if (res?.statusCode !== RRPFulfilment.apiStatus.SUCCESS) {
        isReserveFailed = true;
        break;
      }
    }

    return !isReserveFailed;
  };

  const deliveryDetailsCourierPayload = (
    slots,
    deliverySelectedSlot,
    pickupType
  ) => {
    const selectedSlot = getDoorStepDeliveryTimeSlot({
      slots,
      deliverySelectedSlot,
      pickupType
    });

    const slotStartDate = formattedDate(deliverySelectedSlot.selectedDate);
    const dateEpoch = getStartEndDateEpoch(
      selectedSlot?.timeDescription,
      slotStartDate
    );

    return {
      deliveryMethod: RRPFulfilment.deliveryMethodApi[pickupType],
      popStationCode: '',
      slotCode: selectedSlot?.slotCode,
      slotStartDate: slotStartDate,
      slotEndDate: slotStartDate,
      timeDescription: selectedSlot?.timeDescription,
      slotStartDateEpoch: dateEpoch?.slotStartDateEpoch,
      slotEndDateEpoch: dateEpoch?.slotEndDateEpoch,
      slotId: selectedSlot?.slotStartDate,
      appointmentId: `${selectedSlot?.slotCode}-${selectedSlot?.slotStartDate}`
    };
  };

  const deliveryDetailsPopComPayload = (
    fulfilmentValue: KeyValue,
    orderId: string
  ) => {
    const orderDetailsData = orderModelRef.current?.find(
      order => order.orderId === orderId
    )?.methods?.data;
    const selectedTimeSlot = getSelectedPopStationTimeSlot(
      orderDetailsData?.timeSlotsActual,
      fulfilmentValue?.date
    );
    const slotStartDate = formattedDate(selectedTimeSlot.slotDate);
    const dateEpoch = getStartEndDateEpoch(
      selectedTimeSlot.slotDesc,
      slotStartDate
    );

    const popStationcode = fulfilmentValue?.popStation?.kioskId;
    const selectedPopstation = orderDetailsData.popStations.find(
      (stations: { KioskId: string }) => stations.KioskId === popStationcode
    );
    const floorNumber = selectedPopstation.Storey || selectedPopstation.floorNo;
    const floorData = floorNumber ? { floor: floorNumber } : {};

    return {
      deliveryMethod:
        RRPFulfilment.deliveryMethodApi[PICKUP_TYPE_TO_KEY.POPSTATION],
      slotStartDate: slotStartDate,
      slotEndDate: slotStartDate,
      slotStartDateEpoch: dateEpoch?.slotStartDateEpoch,
      slotEndDateEpoch: dateEpoch?.slotEndDateEpoch,
      slotCode: selectedTimeSlot?.slotCode,
      slotId: selectedTimeSlot?.id,
      timeDescription: selectedTimeSlot.slotDesc,
      popStationCode: popStationcode,
      appointmentId: selectedTimeSlot?.id,
      deliveryAddress: {
        buildingName: selectedPopstation?.BuildingName,
        blockOrHouseNum: selectedPopstation?.HouseBlockNumber,
        unitNumber: selectedPopstation?.UnitNumber || null,
        houseNumber:
          selectedPopstation?.HouseBlockNumber ||
          selectedPopstation?.blockOrHouseNum,
        postcode: selectedPopstation.ZipCode,
        zipcode: selectedPopstation.PostCode || selectedPopstation.postcode,
        streetName:
          selectedPopstation.StreetName || selectedPopstation.streetName,
        country: 'SGP',
        ...floorData
      }
    };
  };

  const getFulfilmentBasedParams = (
    fulfilmentPageData: KeyValue,
    bag: KeyValue
  ) => {
    const pickupType = fulfilmentPageData[bag.orderId]?.fulfilmentMethod;
    switch (pickupType) {
      case PICKUP_TYPE_TO_KEY.DOORSTEP_DELVIERY:
      case PICKUP_TYPE_TO_KEY.PARTNER_DELIVERY:
        return deliveryDetailsCourierPayload(
          actualDeliverySlots.current?.[bag.orderId],
          {
            selectedDate: fulfilmentPageData[bag.orderId]?.selectedDate,
            selectedTime: fulfilmentPageData[bag.orderId]?.selectedTime
          },
          fulfilmentPageData[bag.orderId]?.fulfilmentMethod
        );
      case PICKUP_TYPE_TO_KEY.POPSTATION:
        return deliveryDetailsPopComPayload(
          fulfilmentPageData[bag.orderId],
          bag.orderId
        );
      default:
        return {};
    }
  };

  const callbackModel = {
    onProceed: async (fulfilmentPageData: KeyValue) => {
      try {
        const { deliveryAddress } = fulfilmentPageData || {};
        const floorUnitNumber = formatFloorUnitNumber(
          deliveryAddress?.floor,
          deliveryAddress?.unitNumber
        );
        const detailsForOrders = orderModelRef.current.map((bag: KeyValue) => {
          return {
            orderId: bag.orderId,
            addressId: '',
            paymentMethod: RRPFulfilment.paymentMode.CASH,
            deliveryAddress: {
              ...deliveryAddress,
              country: 'SGP',
              floor: floorUnitNumber,
              unitNumber: floorUnitNumber
            } /**COUNTRY- hard-coded value to be sent */,
            ...getFulfilmentBasedParams(fulfilmentPageData, bag)
          };
        });

        setFulfilmentConfigs((prevState: Fulfilmentconfigs) => ({
          ...prevState,
          isLoading: true
        }));
        const isReservationsSuccess = await reserveLogistics(detailsForOrders);
        if (isReservationsSuccess) {
          const res = await rrpSetDeliveryDetails({
            rrpMasterOrderId: rrpCheckout.rrpMasterOrderId,
            payload: detailsForOrders as APP_TYPE_ANY
          });

          if (res?.result === RRPFulfilment.apiStatus.SUCCESS) {
            navigate(`/${navigation.RRP_ORDERSUMMARY}`);
          }
          setFulfilmentConfigs((prevState: Fulfilmentconfigs) => ({
            ...prevState,
            isLoading: false
          }));
        }

        dataLayerPush(
          rrpReviewOrderDataLayer({
            baseParams: {
              cartItems: rrpCart?.addedItems,
              orderModel: rrpCheckout?.orderModel,
              location: window.location
            }
          })
        );
      } catch (e) {
        setFulfilmentConfigs((prevState: Fulfilmentconfigs) => ({
          ...prevState,
          hasError: true,
          isLoading: false
        }));
      }
    }
  };

  const getAddressInfo = () => {
    return (
      addressFormRef?.current?.address || {
        ...addressInfo?.address,
        ...(addressInfo?.validFloors?.length
          ? { floor: '', unitNumber: '' }
          : {})
      }
    );
  };

  return (
    <>
      {orderDetails?.ImplRRPOrderDetails &&
        fulfilmentConfigs.showFulfilment &&
        orderModel && (
          <Fulfilmentv2
            orders={orderModel}
            addressModel={{
              onSearchAddress: handleRetrieveAddress,
              address: getAddressInfo(),
              confirmAddress: {},
              configs: addressFormRef?.current?.configs || {},
              validFloors: addressInfo?.validFloors || [],
              showInlineAddressForm: true
            }}
            callbacks={callbackModel}
          />
        )}
      {fulfilmentConfigs.hasError && renderError()}
      <Loader />
    </>
  );
};

export default Delivery;
