import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useDispatch } from 'react-redux';
import { getUrlParams, setUrlParams as setUrlParamFn } from '@lux/helpers';
import SEO from '../../components/SEO';
import { RRPPhoneCatalog } from '../../components/RRPCatalog/index';
import '../../fragments/catalog-phone-detail';
import '../../fragments/plan-detail';
import '../../fragments/aem-components';
import { ACTION_TYPES } from '../../constants/actions';
import { CatalogSkeletonLoader } from '../../components/PhoneCatalog/SkeletonLoader';
import { useGatsbyInit } from '../../hooks/useGatsbyInit';
import { trans as t } from '../../helpers/localisation';
import { navigation } from '../../middlewares/navigation-constants';

const RRPCatalog = ({ data, cisPlanRates }) => {
  let _urlParams = null;
  const locationData = useLocation();
  const dispatch = useDispatch();
  const { isInit } = useGatsbyInit();
  if (cisPlanRates) {
    navigate(setUrlParamFn('/cis-phones', getUrlParams()));
  }

  const [urlParams, setUrlParams] = useState({});
  useEffect(() => {
    _urlParams = getUrlParams();
    setUrlParams(_urlParams);
    dispatch({ type: ACTION_TYPES.IPHONE.RESET_DATA });
  }, []);

  const renderPhoneCatalog = () => {
    if (!isInit) return <CatalogSkeletonLoader />;

    return <RRPPhoneCatalog data={data} location={locationData} />;
  };
  return (
    <div className="fs-unmask">
      <SEO
        title={t('RRP_CATALOG_TITLE')}
        pathname={`/${navigation.RRP_PRODUCT_CATALOG}`}
        description={t('RRP_CATALOG_DESCRIPTION') as string}
        pageTitle={t('RRP_CATALOG_TITLE') as string}
        pageDescription={t('RRP_CATALOG_DESCRIPTION') as string}
        keywords={t('RRP_CATALOG_KEYWORDS') as string}
        hasDefaultTitle={false}
      />
      {renderPhoneCatalog()}
    </div>
  );
};

export default RRPCatalog;

export const query = graphql`
  {
    allRrpProduct {
      edges {
        node {
          ...RRPProductDetailFragment
        }
      }
    }
    allPlanGroup(filter: { segment: { in: "res" } }) {
      edges {
        node {
          defaultPlan
          groupName
        }
      }
    }

    allRrpPriceRange {
      edges {
        node {
          text
          value
        }
      }
    }
    allRrpBrand {
      edges {
        node {
          text
          value
        }
      }
    }
    allRrpCategory {
      edges {
        node {
          text
          value
        }
      }
    }
    allRrpDeal {
      edges {
        node {
          text
          value
        }
      }
    }
    allRrpPaymentOption {
      edges {
        node {
          text
          value
        }
      }
    }
    allRrpBanner {
      edges {
        node {
          id
          title
          description
          theme
          sectionTitle
          sectionDescription
          ctaText
          ctaLink
          desktopFileReference
          mobileFileReference
          enableCTA
        }
      }
    }

    allVariableConfig {
      edges {
        node {
          name
          value
        }
      }
    }

    allAemPages(filter: { pageUrl: { eq: "/eshop/mobile/res/phones.xjson" } }) {
      edges {
        node {
          title
          components {
            ...AemComponentsFragment
          }
        }
      }
    }
  }
`;
