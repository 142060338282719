import { ACTION_TYPES as SHOP_ACTION_TYPES } from '../constants/actions';
import { REQUEST_STATUS } from '../constants';
import {
  ACTION_TYPES,
  CreateRRPIPPOrderResponse,
  ImplRRPAddMasterOrderResponse,
  OrderItemDetail,
  RetrieveIppSubscriptionInfoResponse,
  SubscriptionIppDetail
} from '@detox/actions';
import { Action, AemVariableConfig, AppState } from '../types/common.types';
import {
  CartBoxReturnValue,
  PaymentTerm
} from '@common-modules/product-catalogue';
import { getOrderItemDetails } from '../helpers/rrpCart';
import RRP_CART_CONSTANTS from '../constants/rrpcart';
import { Dispatch } from 'redux';
import { getAemConfig } from '../helpers/common';
import { AEM_CONFIG_KEYS } from '../config/common';

const SET_UIAM_TARGET_URL_ERROR = ACTION_TYPES.UIAM.SET_UIAM_TARGET_URL_ERROR;
const {
  CREATE_ORDER_LOADING,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  RRP_ADD_MASTER_ORDER_LOADING,
  RRP_ADD_MASTER_ORDER_ERROR,
  RRP_ADD_MASTER_ORDER_SUCCESS,
  RESET_STATE,
  RRP_SHOPPING_CART_LOADING,
  RRP_SHOPPING_CART_SUCCESS,
  RRP_SHOPPING_CART_ERROR,
  RRP_REMOVE_MASTER_ORDER_LOADING,
  RRP_REMOVE_MASTER_ORDER_SUCCESS,
  RRP_REMOVE_MASTER_ORDER_ERROR,
  RRP_CANCEL_MASTER_ORDER_LOADING,
  RRP_CANCEL_MASTER_ORDER_SUCCESS,
  RRP_CANCEL_MASTER_ORDER_ERROR,
  RRP_EXTEND_SESSION_LOADING,
  RRP_EXTEND_SESSION_ERROR,
  RRP_EXTEND_SESSION_SUCCESS,
  RETRIEVE_RRP_IPP_DESCRIPTION_LOADING,
  RETRIEVE_RRP_IPP_DESCRIPTION_FAIL,
  RETRIEVE_RRP_IPP_DESCRIPTION_SUCCESS,
  RRP_CREATE_IPP_ORDER_LOADING,
  RRP_CREATE_IPP_ORDER_SUCCESS,
  RRP_CREATE_IPP_ORDER_FAIL_HAS_PENDING_PAYMENT,
  RRP_CREATE_IPP_ORDER_FAIL,
  RRP_SET_TEMP_IPP_CART_ITEM,
  RRP_CLEAR_ORDER,
  RRP_IPP_NOT_ELIGIBLE,
  DELETE_IPP_ORDER_SUCCESS,
  DELETE_IPP_ORDER_LOADING,
  DELETE_IPP_ORDER_FAILED,
  RRP_CANCEL_ORDER_SUCCESS
} = ACTION_TYPES.RRP_CART;

export interface RRPCartState extends AppState {
  pagePathname?: string;
  masterOrderId: string;
  addedItems: OrderItemDetail[];
  addItemSuccess?: boolean;
  finishCancelMasterOrder?: boolean;
  finishEmptyCart?: boolean;
  addItemAfterCancel?: boolean;
  finishCreateIppOrder?: boolean;
  isEligibleForIPP?: boolean;
  ippSubscriptions?: SubscriptionIppDetail[];
  tempCartItem?: CartBoxReturnValue;
  selectedPaymentTerm?: PaymentTerm;
  shouldGoToCart?: boolean;
  hasPriceSlash?: boolean;
  notEligiblePlan?: string[];
  ippOrderData?: {
    customerId: string;
    productOrderId: string;
    productOrderItemId: string;
    productOrderReferenceNumber: string;
    serviceId?: string;
  };
  showEmptyCart?: boolean;
}

export interface TempCartItem {
  cartBoxData: CartBoxReturnValue;
  pathName?: string;
}

export interface ResetStateOptions {
  clearIppServices?: boolean;
}

const initialState: RRPCartState = {
  masterOrderId: null,
  addedItems: [],
  isLoading: false,
  isError: false,
  addItemSuccess: false,
  status: REQUEST_STATUS.IDLE,
  acknowledgeNotification: false,
  isEligibleForIPP: null,
  ippSubscriptions: [],
  finishCreateIppOrder: false,
  skeletonLoading: false,
  hasPriceSlash: false,
  notEligiblePlan: []
};

const rrpCartReducer = (state = initialState, action: Action): RRPCartState => {
  switch (action.type) {
    case SHOP_ACTION_TYPES.RRP.INIT: {
      const aemVariableConfigs = action.value as AemVariableConfig[];
      const notEligiblePlan = getAemConfig(
        aemVariableConfigs,
        AEM_CONFIG_KEYS.DETOX_RRP_IPP_NOT_ELIGIBLE_PLANS
      )?.split(',');
      return {
        ...state,
        showEmptyCart: false,
        hasPriceSlash:
          getAemConfig(
            aemVariableConfigs,
            AEM_CONFIG_KEYS.DETOX_ENABLE_RRP_PRICE_SLASH
          ) === 'true',
        notEligiblePlan
      };
    }
    case RRP_SHOPPING_CART_LOADING: {
      return {
        ...state,
        skeletonLoading: true,
        error: false,
        status: REQUEST_STATUS.PENDING
      };
    }
    case RRP_SHOPPING_CART_SUCCESS: {
      return {
        ...state,
        rrpOrder: action.value,
        skeletonLoading: false,
        error: false,
        status: REQUEST_STATUS.SUCCEEDED
      };
    }
    case RRP_SHOPPING_CART_ERROR: {
      return {
        ...state,
        rrpOrder: null,
        skeletonLoading: false,
        error: true,
        status: REQUEST_STATUS.FAILED
      };
    }
    case RRP_REMOVE_MASTER_ORDER_LOADING: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    case RRP_REMOVE_MASTER_ORDER_ERROR: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }
    case RRP_REMOVE_MASTER_ORDER_SUCCESS: {
      const removedItemId =
        action.value?.orderItemsWithRemoveResults?.[0]?.rrpOrderItemId;
      const addedItems = state.addedItems.reduce((result, item) => {
        if (item.orderItemId !== removedItemId) {
          result.push(item);
        }
        return result;
      }, []);
      return {
        ...state,
        loading: false,
        error: false,
        removedItems: action.value,
        addedItems
      };
    }
    case RRP_CANCEL_MASTER_ORDER_LOADING: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    case RRP_CANCEL_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false
      };
    }
    case RRP_CANCEL_MASTER_ORDER_SUCCESS: {
      return {
        ...state,
        rrpOrder: null,
        loading: false,
        error: false,
        addedItems: [],
        removedItems: null,
        masterOrderId: null,
        finishCancelMasterOrder: true,
        addItemAfterCancel: action.value?.addItemAfterCancel,
        ippSubscriptions: []
      };
    }

    case RRP_CANCEL_MASTER_ORDER_ERROR: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }

    case RRP_EXTEND_SESSION_LOADING: {
      return {
        ...state,
        loading: true,
        error: false,
        isExtended: null
      };
    }
    case RRP_EXTEND_SESSION_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        isExtended: null
      };
    }
    case RRP_EXTEND_SESSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        isExtended: true
      };
    }
    case RRP_ADD_MASTER_ORDER_LOADING: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    case RRP_ADD_MASTER_ORDER_SUCCESS: {
      const data = (action.value?.orderItemDetails || []) as OrderItemDetail[];
      return {
        ...state,
        loading: false,
        error: false,
        addedItems: [...state.addedItems, ...data],
        addItemSuccess: true,
        shouldGoToCart: action.value?.shouldGoToCart
      };
    }
    case RRP_ADD_MASTER_ORDER_ERROR: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }
    case CREATE_ORDER_LOADING: {
      return { ...state, loading: true };
    }
    case CREATE_ORDER_FAIL: {
      return { ...state, isError: true, loading: false };
    }
    case CREATE_ORDER_SUCCESS: {
      const data = action.data as ImplRRPAddMasterOrderResponse;
      return {
        ...state,
        loading: false,
        masterOrderId: data.masterOrderId,
        addedItems: data.implRRPCreateOrderResponse?.[0]?.orderItemDetails,
        addItemSuccess: true,
        shouldGoToCart: data.shouldGoToCart
      };
    }

    case RETRIEVE_RRP_IPP_DESCRIPTION_LOADING: {
      return { ...state, skeletonLoading: true };
    }
    case RETRIEVE_RRP_IPP_DESCRIPTION_FAIL: {
      return { ...state, isError: true, skeletonLoading: false };
    }
    case RETRIEVE_RRP_IPP_DESCRIPTION_SUCCESS: {
      const data = action.data as RetrieveIppSubscriptionInfoResponse;
      const ippSubscriptions = data.subscriptionIPPDetails?.map(
        subscription => {
          const isPlanEligible = !state.notEligiblePlan.includes(
            subscription.planDetails?.planId
          );
          const isEligible =
            isPlanEligible &&
            subscription.accessoryIPPEligibilityDetails?.isIPPEligible;
          return {
            ...subscription,
            isEligible
          };
        }
      );

      const validSubscriptions = ippSubscriptions.filter(
        subscription => subscription.isEligible
      );

      return {
        ...state,
        ippSubscriptions,
        isEligibleForIPP: validSubscriptions?.length > 0,
        skeletonLoading: false,
        isError: false
      };
    }

    case SET_UIAM_TARGET_URL_ERROR: {
      return {
        ...state
      };
    }

    case RRP_CREATE_IPP_ORDER_LOADING: {
      return {
        ...state,
        isLoading: true
      };
    }
    case RRP_CREATE_IPP_ORDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    }

    case RRP_SET_TEMP_IPP_CART_ITEM: {
      const { cartBoxData, pathName } = action.data as TempCartItem;
      return {
        ...state,
        tempCartItem: cartBoxData,
        pagePathname: pathName
      };
    }
    case RRP_CREATE_IPP_ORDER_SUCCESS: {
      const data = action.data as CreateRRPIPPOrderResponse;
      const {
        rrpOrderId,
        ippOrderId,
        implSubmitProductConfigurationRestOutput,
        customerId
      } = data;
      const {
        updateProductConfigurationResponse
      } = implSubmitProductConfigurationRestOutput;
      const {
        productOrderItemID,
        productOrderID,
        productOrderReferenceNumber,
        productConfiguration
      } = updateProductConfigurationResponse || {};
      const tempIppCartItem = state.tempCartItem;
      const cartItemDetails = getOrderItemDetails({
        product: tempIppCartItem?.product,
        quantity: tempIppCartItem.quantity
      });
      const cartItem: OrderItemDetail = {
        identifier: tempIppCartItem?.product?.deviceId,
        orderId: ippOrderId,
        orderItemId: productOrderItemID,
        sku: tempIppCartItem?.product?.sku,
        quantity: tempIppCartItem?.quantity,
        productDetails: cartItemDetails[0].productDetails,
        itemType: RRP_CART_CONSTANTS.CART_ITEM_TYPES.IPP
      };
      return {
        ...state,
        addedItems: [cartItem],
        isError: false,
        isLoading: false,
        finishCreateIppOrder: true,
        ippOrderData: {
          customerId,
          productOrderId: productOrderID,
          productOrderItemId: productOrderItemID,
          productOrderReferenceNumber,
          serviceId: productConfiguration?.serviceID
        }
      };
    }
    case DELETE_IPP_ORDER_LOADING: {
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    }

    case DELETE_IPP_ORDER_FAILED: {
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    }

    case SHOP_ACTION_TYPES.CART.EMPTY_CART_SUCCESS: {
      return {
        ...state,
        isError: false,
        isLoading: false,
        ippOrderData: null,
        finishCancelMasterOrder: true,
        addItemAfterCancel: action.value?.addItemAfterCancel,
        addedItems: [],
        masterOrderId: null,
        ippSubscriptions: [],
        finishEmptyCart: true
      };
    }
    case DELETE_IPP_ORDER_SUCCESS: {
      return {
        ...state,
        isError: false,
        isLoading: false,
        ippOrderData: null,
        finishCancelMasterOrder: true,
        addItemAfterCancel: action.value?.addItemAfterCancel,
        shouldGoToCart: action.value?.shouldGoToCart,
        addedItems: [],
        masterOrderId: null,
        ippSubscriptions: []
      };
    }

    case RESET_STATE: {
      const { clearIppServices } = (action.value || {}) as ResetStateOptions;
      return {
        ...state,
        isLoading: false,
        addItemSuccess: false,
        finishCreateIppOrder: false,
        finishCancelMasterOrder: false,
        finishEmptyCart: false,
        shouldGoToCart: false,
        isEligibleForIPP: null,
        addItemAfterCancel: false,
        ippSubscriptions: clearIppServices ? [] : state.ippSubscriptions
      };
    }
    case RRP_CLEAR_ORDER: {
      return {
        ...state,
        rrpOrder: null
      };
    }
    case SHOP_ACTION_TYPES.RRP.RRP_CLEAR_ORDER_DATA:
    case SHOP_ACTION_TYPES.PAYMENT.CLEAR_BUY_FLOW_DATA: {
      return { ...initialState, showEmptyCart: action.value?.showEmptyCart };
    }

    case RRP_IPP_NOT_ELIGIBLE: {
      return {
        ...state,
        isEligibleForIPP: false,
        isLoading: false,
        skeletonLoading: false
      };
    }
  }

  return state;
};

export const clearOrderDetails = () => (dispatch: Dispatch) => {
  dispatch({ type: RRP_CLEAR_ORDER });
};

export const resetCartState = (options?: ResetStateOptions) => (
  dispatch: Dispatch
) => {
  dispatch({ type: RESET_STATE, value: options });
};

export const setTempCartItem = (data: TempCartItem) => (dispatch: Dispatch) => {
  dispatch({
    type: ACTION_TYPES.RRP_CART.RRP_SET_TEMP_IPP_CART_ITEM,
    data
  });
};

export const initRRP = (aemVariableConfigs: AemVariableConfig[]) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SHOP_ACTION_TYPES.RRP.INIT,
    value: aemVariableConfigs
  });
};

export default rrpCartReducer;
